import {Box} from "@mui/material";
import {useTranslation} from "react-i18next";

const NoTableData = () => {
  const { t } = useTranslation()

  return (
    <Box sx={{
      fontSize: '32px',
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      whiteSpace: 'nowrap'
    }}>
      {/* @ts-ignore */}
      {t('noTableData.title')}
    </Box>
  )
}

export default NoTableData;