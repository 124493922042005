import {Box, Button} from "@mui/material";
import ImageCarousel from "../../ImageCarousel";
import Description from "./components/Description";
import ChoosenFooter from "./components/ChoosenFooter";
import {useTranslation} from "react-i18next";
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import {useProjectStore} from "../../../../../../store/projectStore";
import {useProjectsStore} from "../../../../../../store/projectsStore";
import useAsyncWrapper from "../../../../../../hooks/useAsyncWrapper";
import removeNull from "../../../../../../utils/removeNull";
import useToast from "../../../../../../hooks/useToast";
import EditIcon from '@mui/icons-material/Edit';
import {memo, useState} from "react";

interface IProps {
  closeModal?: () => void;
}
const ChoosenAccessory = ({ closeModal = () => {} } : IProps) => {
  const { t } = useTranslation()
  const { warningToast, successToast, errorToast } = useToast()
  const [isLoading, setIsLoading] = useState(false)


  const choosenItem = useProjectStore(state => state.choosenAccessory)
  const currentPosition = useProjectStore(state => state.currentPosition)
  const project_positions = useProjectStore(state => state.project_positions)

  const choosenAccessory = useProjectStore(state => state.choosenAccessory)
  const accessoryQuantity = useProjectStore(state => state.accessoryQuantity)
  const trayAccessory = useProjectStore(state => state.trayAccessory)


  const currentProject = useProjectsStore(state => state.currentProject)
  const editPosition = useProjectStore(state => state.editPosition)
  const getLidByAccessory = useProjectStore(state => state.getLidByAccessory)
  const loadingCart = useProjectStore(state => state.loadingCart)

  const editPositionHandler = useAsyncWrapper(editPosition)
  const getLidByAccessoryHandler = useAsyncWrapper(getLidByAccessory)


  const handleEdit = async() => {
      const currentAccessories = project_positions?.find(position => position.id === trayAccessory?.id)?.accessories ?? [];
      setIsLoading(true)
      let lid_model_parameter = null

      if(trayAccessory?.with_lid) {
        const {data, error} = await getLidByAccessoryHandler([choosenAccessory?.id])
        lid_model_parameter = data.data

        if(!lid_model_parameter) {
          // @ts-ignore
          warningToast(t('project.noLidFound'))
        }
      }


      const payload = {
        accessories: [
          ...currentAccessories,
          {
            count: accessoryQuantity,
            model_parameter: choosenAccessory,
            lid_model_parameter: lid_model_parameter || null
          }
        ],
        project_id: currentProject?.id,
      }

      const { data, error } = await editPositionHandler([removeNull(payload), trayAccessory?.id])
      setIsLoading(false)
      if(!error) {
        // @ts-ignore
        successToast(t('project.positionEditSuccess'));
        closeModal()
        return
      }
      // @ts-ignore
      errorToast(t('project.positionEditFailed'))
  }



  return (
    <Box sx={{
      border: '1px solid #E5E5E5',
      padding: '10px',
      borderRadius: '10px',
      height: '100%',
      overflowY: 'auto',
      display: 'flex',
      flexDirection: 'column',
    }}>
      <Box sx={{
        display: 'flex',
        gap: '20px'
      }}>
        <ImageCarousel images={choosenItem?.images ?? []}/>
        <Description />
      </Box>
      <ChoosenFooter />
      <Box sx={{
        height: '100%'
      }} />
      <Button
        color={'error'}
        onClick={handleEdit}
        disabled={loadingCart || isLoading}
        sx={{
          padding: '8px 10px',
          fontSize: '12px',
          fontWeight: '600',
          borderRadius: '8px',
          lineHeight: '1.5em',
          alignSelf: 'flex-end',
          marginTop: '5px'
        }}
        variant={'contained'}
        startIcon={currentPosition ? <EditIcon /> : <AddShoppingCartIcon />}>
          {/* @ts-ignore */}
        {t(currentPosition ? 'project.editCart' : 'project.addToCart')}
      </Button>
    </Box>
  )
}

export default memo(ChoosenAccessory)