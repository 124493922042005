import { Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useHandbooksStore } from "../../../../../store/handbooksStore";
import HandbookCard from "./HandbookCard";
import NoTableData from "../../../NoTableData";

const HandbookList = () => {
  const { t } = useTranslation();

  const handbooks = useHandbooksStore((state) => state.tableData.data);

  return (
    <Box
      sx={{
        display: "flex",
        gap: "30px",
        position: "relative",
        flexWrap: "wrap",
        height: handbooks.length ? "auto" : "100%",
        "@media (max-width: 600px)": {
          justifyContent: "center",
        },
      }}
    >
      {handbooks.length ? (
        handbooks?.map((el) => <HandbookCard key={el.id} handbook={el} />)
      ) : (
        <NoTableData />
      )}
    </Box>
  );
};

export default HandbookList;
