import {TableHead, TableRow} from "@mui/material";
import {useTranslation} from "react-i18next";
import {StyledTableCell} from "./style";

const ResultsTableHead = () => {
  const { t } = useTranslation()

  return <TableHead sx={{
    borderBottom: '3px double #ccc'
  }}>
    <TableRow>
      <StyledTableCell>
        {/* @ts-ignore */}
        {t('common.description')}
      </StyledTableCell>
      <StyledTableCell>
        {/* @ts-ignore */}
          {t('coatings.code')}
      </StyledTableCell>
    </TableRow>
  </TableHead>
}

export default ResultsTableHead