import {Box, Button, TableBody, TableRow, Typography} from "@mui/material";
import {useTranslation} from "react-i18next";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import {StyledActionButton, StyledTableCell} from "./style";
import {formatDate} from "../../../../../../utils/formatDate";
import {useAdminsTableStore} from "../../../../../../store/adminsTableStore";
import useAsyncWrapper from "../../../../../../hooks/useAsyncWrapper";
import ConfirmDelete from "../../../../Modals/ConfirmDelete";
import {useState} from "react";
import EditUser from "../../../../Modals/EditUser";
import usePermission from "../../../../../../hooks/usePermission";
import {PermissionsUser} from "../../../../../../interfaces/enum";
import useToast from "../../../../../../hooks/useToast";
import errorsToRecord from "@hookform/resolvers/io-ts/dist/errorsToRecord";


interface IProps {
  data: any[]
}
const AdminsTableBody = ({ data }: IProps) => {
  const { t } = useTranslation()
  const { hasPermission } = usePermission()
  const { errorToast, successToast} = useToast()
  const [id, setId] = useState(0);
  const [open, setOpen] = useState({
    delete: false,
    edit: false
  });

  const handleOpenDelete = (id: number) => {
    setId(id)

    setOpen({
      ...open,
      delete: true
    })
  }
  const handleOpenEdit = (id: number) => {
    setId(id)
    setOpen({
      ...open,
      edit: true
    })
  }
  const handleCloseDelete = () => {
    setOpen({
      ...open,
      delete: false
    })
    setId(0)
  }
  const handleCloseEdit = () => {
    setOpen({
      ...open,
      edit: false
    })
    setId(0)
  }

  const deleteUser = useAdminsTableStore(state => state.deleteUser);
  const deleteUserHandler = useAsyncWrapper(deleteUser);

  const handleDeleteUser = async (id: number) => {
    const {error} = await deleteUserHandler([id])
    if(!error) {
      // @ts-ignore
      successToast(t('deleteUser.success'))
      return;
    }
    // @ts-ignore
    errorToast(t('deleteUser.error'));
  }


  return (<TableBody>
    {data
      ?.map((row) => {
        return (
          <TableRow
            hover
            role="checkbox"
            tabIndex={-1}
            key={row.id}
            sx={{
              '&.MuiTableRow-hover:hover': {
                opacity: 0.8,
              },
              '& .MuiTableCell-root': {
                backgroundColor: row.blocked ? '#d32f2f' : '#D8D9DA',
                color: row.blocked ? 'white' : 'black'
              }
          }}>
            <StyledTableCell>
              <Typography>{row.id}</Typography>
            </StyledTableCell>
            <StyledTableCell>
              {/* @ts-ignore */}
              <Typography>{row.email} {row.blocked ? `(${t('usersTable.blocked')})` : ''}</Typography>
            </StyledTableCell>
            <StyledTableCell>
              <Typography>{row.first_name}</Typography>
            </StyledTableCell>
            <StyledTableCell>
              <Typography>{row.last_name}</Typography>
            </StyledTableCell>
            <StyledTableCell>
              <Typography>{row.patronymic}</Typography>
            </StyledTableCell>
            <StyledTableCell>
              <Typography>{row.company}</Typography>
            </StyledTableCell>
            <StyledTableCell>
              <Typography>{row.phone_number}</Typography>
            </StyledTableCell>
            <StyledTableCell>
              <Typography>{row?.role?.name}</Typography>
            </StyledTableCell>
            <StyledTableCell>
              <Typography sx={{
                background: row.active ? 'green' : 'red',
                borderRadius: '5px',
                width: 'fit-content',
                padding: '0 8px'
              }}
                          color={'white'}>
                            {/* @ts-ignore */}
                {t(row.active ? 'usersTable.active' : 'usersTable.nonActive') }
              </Typography>
            </StyledTableCell>
            <StyledTableCell>
              <Typography>{row.last_login && formatDate(row.last_login, true)}</Typography>
            </StyledTableCell>
            <StyledTableCell>
            <Box sx={{
              display: 'flex',
              gap: '5px'
            }}>
              <StyledActionButton disabled={!hasPermission(PermissionsUser.userUpdate)} onClick={() => handleOpenEdit(row.id)}>
                <EditIcon color={'warning'} />
              </StyledActionButton>
              <StyledActionButton disabled={!hasPermission(PermissionsUser.userDestroy)} onClick={() => handleOpenDelete(row.id)}>
                <DeleteIcon color={row.blocked ? 'action' : 'error'}/>
              </StyledActionButton>
            </Box>
          </StyledTableCell>
          </TableRow>
        );
      })}
    <ConfirmDelete
    // @ts-ignore
      text={t('deleteUser.submissionText')}
      // @ts-ignore
      title={t('deleteUser.title')}
      handleSubmit={() => handleDeleteUser(id)}
      handleClose={handleCloseDelete}
      open={open.delete}
    />
    <EditUser
      id={id}
      open={open.edit}
      handleClose={handleCloseEdit}
    />
  </TableBody>)
}

export default AdminsTableBody