import {Box, Dialog, DialogContent, Typography} from "@mui/material";
import {StyledDialogTitle} from "../../Auth/ForgotPassword/style";
import CloseSvg from "../../../../assets/svgs/common/CloseSvg";
import React, {useEffect} from "react";
import {useTranslation} from "react-i18next";
import useAsyncWrapper from "../../../../hooks/useAsyncWrapper";
import CircularProgress from "@mui/material/CircularProgress";
import Stack from "@mui/material/Stack";
import EditRecommendationForm from "./EditRecommendationForm";
import {useRecommendationsStore} from "../../../../store/recommendationsStore";

interface ISettingsProps {
  open: boolean;
  handleClose: () => void;
  id: number;
}
const EditRecommendation = ({ handleClose, open, id } : ISettingsProps ) => {
  const { t } = useTranslation()
  const [isLoading, setIsLoading] = React.useState(true);
  const [data, setData] = React.useState(null);

  const getRecommendation = useRecommendationsStore(state => state.getRecommendation);
  const getRecommendationHandler = useAsyncWrapper(getRecommendation)


  useEffect(() => {
    if(open) {
      (async () => {
        const { data } = await getRecommendationHandler([id]);
        setIsLoading(false)
        setData(data.data)
      })()
    } else {
      setIsLoading(true)
    }
  },[open])

  return <Dialog
    open={open}
    onClose={handleClose}
  >
    <StyledDialogTitle>
      <Typography
        fontWeight={600}
        variant={'h6'}
        color={'#373737'}
      >
        {/* @ts-ignore */}
        {t('recommendation.edit')}
      </Typography>
      <Box onClick={handleClose}>
        <CloseSvg />
      </Box>
    </StyledDialogTitle>


    <DialogContent>
      {isLoading ? <Stack
          direction="row"
          justifyContent="center"
          alignItems="center"
          spacing={0}
          sx={{
            width: '100%'
          }}
        >
          <CircularProgress size={30} />
        </Stack> :
        <EditRecommendationForm item={data!} handleClose={handleClose}/>}
    </DialogContent>
  </Dialog>

}

export default EditRecommendation