import {Controller, useForm} from "react-hook-form";
import {ICreateTrayForm,
} from "../../../../../interfaces/general";
import {yupResolver} from "@hookform/resolvers/yup";
import {StyledFormControlLabel} from "./style";
import {
  Box,
  Button,
  Checkbox,
  Grid,
  ListItemText,
  MenuItem,
  Select,
  TextField
} from "@mui/material";
import ButtonLoadingWrapper from "../../../../Wrappers/ButtonLoading";
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import useAsyncWrapper from "../../../../../hooks/useAsyncWrapper";
import useToast from "hooks/useToast";

import {useTraysStore} from "../../../../../store/traysStore";
import {getTrayCreateValidationSchema} from "../../../../../validations/trays/createTray";
import {useImagesStore} from "../../../../../store/imagesStore";
import CustomImageField from "../../../CustomImageField";
import { trayTypes} from "../../../../../constants";
import { useLidsStore} from "../../../../../store/lidsStore";


interface IProps {
  handleClose: () => void;
}
const initialFormState = {
  name_uk: '',
  name_en: '',
  article_prefix_2: '',
  article_prefix_1: '',
  type: "tray",
  lid_ids: [],
  partition_ids: [],
}

const CreateTrayForm = ({ handleClose }: IProps) => {
  const { t } = useTranslation()
  const { errorToast, successToast } = useToast();

  const [isLoading, setIsLoading] = useState(false);


  const createTray = useTraysStore(state => state.createTray);
  const lids = useLidsStore(state => state.tableData.data);
  const choosedImages = useImagesStore(state => state.modal.choosedImages)


  const createTrayHandler = useAsyncWrapper(createTray)

  const schema = getTrayCreateValidationSchema(t);

  const { handleSubmit, control } = useForm<ICreateTrayForm>({
    defaultValues: initialFormState,
    resolver: yupResolver(schema),
  });


  const handleCreateSubmit = async (body: ICreateTrayForm) => {
    const payload = {
      ...body,
      image_ids: choosedImages,
    }
    setIsLoading(true)
    const { error, status } = await createTrayHandler( [payload] )
    setIsLoading(false)

    if(!error) {
      // @ts-ignore
      successToast(t('createTray.success'))
      handleClose()
      return;
    }
    if (status === 400) {
      const errors = error?.response?.data?.message ?? [];
      if(error?.response?.data?.message.length > 0) {
        // @ts-ignore
        errors.forEach((err: any) => errorToast(t('error.error'), t('createTray.error.400.' + err?.field)))
      }

      return
    }
// @ts-ignore
    errorToast(t('createTray.error.default'))
  }

  return (
    <Box
      component={'form'}
      onSubmit={handleSubmit(handleCreateSubmit)}
      sx={{
        '@media (min-width: 600px)': {
          minWidth: '350px',
        }
      }}
    >
      <Grid
        container
        spacing={1}
        mb={2}
      >
        <Grid item xs={12} md={6}>
          <Controller
            name={"name_uk"}
            control={control}
            render={({
                       fieldState: {error},
                       field: {onChange, value, name},
                     }) => (
              <StyledFormControlLabel
                labelPlacement="top"
                sx={{
                  alignItems: 'flex-start'
                }}
                // @ts-ignore
                label={t("trays.name_uk")}
                control={
                  <TextField
                    fullWidth
                    onChange={onChange}
                    value={value}
                    error={error?.ref?.name === name}
                    helperText={error ? error.message : ' '}
                  />
                }
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Controller
            name={"name_en"}
            control={control}
            render={({
                       fieldState: {error},
                       field: {onChange, value, name},
                     }) => (
              <StyledFormControlLabel
                labelPlacement="top"
                sx={{
                  alignItems: 'flex-start'
                }}
                // @ts-ignore
                label={t("trays.name_en")}
                control={
                  <TextField
                    fullWidth
                    onChange={onChange}
                    value={value}
                    error={error?.ref?.name === name}
                    helperText={error ? error.message : ' '}
                  />
                }
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Controller
            name={"article_prefix_1"}
            control={control}
            render={({
                       fieldState: {error},
                       field: {onChange, value, name},
                     }) => (
              <StyledFormControlLabel
                labelPlacement="top"
                sx={{
                  alignItems: 'flex-start'
                }}
                // @ts-ignore
                label={t("trays.article_prefix_1")}
                control={
                  <TextField
                    fullWidth
                    onChange={onChange}
                    value={value}
                    error={error?.ref?.name === name}
                    helperText={error ? error.message : ' '}
                  />
                }
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Controller
            name={"article_prefix_2"}
            control={control}
            render={({
                       fieldState: {error},
                       field: {onChange, value, name},
                     }) => (
              <StyledFormControlLabel
                labelPlacement="top"
                sx={{
                  alignItems: 'flex-start'
                }}
                // @ts-ignore
                label={t("trays.article_prefix_2")}
                control={
                  <TextField
                    fullWidth
                    onChange={onChange}
                    value={value}
                    error={error?.ref?.name === name}
                    helperText={error ? error.message : ' '}
                  />
                }
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <Controller
            name={"type"}
            control={control}
            render={({
                       fieldState: {error},
                       field: {onChange, value, name},
                     }) => (
              <StyledFormControlLabel
                labelPlacement="top"
                sx={{
                  alignItems: 'flex-start'
                }}
                // @ts-ignore
                label={t("trays.type")}
                control={(
                  <Select
                    fullWidth
                    sx={{
                      padding: '0px',
                      '& .MuiSelect-select': {
                        padding: '10px 0 10px 10px',
                      }
                    }}
                    value={value}
                    onChange={onChange}
                    defaultValue={trayTypes[0]}
                    error={error?.ref?.name === name}
                  >
                    {trayTypes.map((trayType) => {
                      return (
                        <MenuItem key={trayType} value={trayType}>
                          {/* @ts-ignore */}
                          {t('trays.'+trayType)}
                        </MenuItem>
                      );
                    })}
                  </Select>)
                }
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <Controller
            name={"lid_ids"}
            control={control}
            render={({
                       fieldState: {error},
                       field: {onChange, value, name},
                     }) => (
              <StyledFormControlLabel
                labelPlacement="top"
                sx={{
                  alignItems: 'flex-start'
                }}
                // @ts-ignore
                label={t("trays.lids")}
                control={(
                  <Select
                    fullWidth
                    sx={{
                      padding: '0px',
                      '& .MuiSelect-select': {
                        padding: '10px 0 10px 10px',
                      }
                    }}
                    multiple
                    value={value}
                    renderValue={(selected) => selected.map(id => lids.find(el => el.id === id)?.name_uk).join(', ')}
                    onChange={onChange}
                    error={error?.ref?.name === name}
                  >
                    {lids.map((lid) => {
                      if(lid?.type === 'lid')
                        return (
                          <MenuItem key={lid.id} value={lid.id}>
                            <Checkbox checked={value?.includes(lid.id)}/>
                            <ListItemText primary={lid.name_uk}/>
                          </MenuItem>
                        );
                    })}
                  </Select>)
                }
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <Controller
            name={"partition_ids"}
            control={control}
            render={({
                       fieldState: {error},
                       field: {onChange, value, name},
                     }) => (
              <StyledFormControlLabel
                labelPlacement="top"
                sx={{
                  alignItems: 'flex-start'
                }}
                // @ts-ignore
                label={t("trays.partitions")}
                control={(
                  <Select
                    fullWidth
                    sx={{
                      padding: '0px',
                      '& .MuiSelect-select': {
                        padding: '10px 0 10px 10px',
                      }
                    }}
                    multiple
                    value={value}
                    renderValue={(selected) => selected.map(id => lids.find(el => el.id === id)?.name_uk).join(', ')}
                    onChange={onChange}
                    error={error?.ref?.name === name}
                  >
                    {lids.map((lid) => {
                      if(lid?.type === 'partition')
                        return (
                          <MenuItem key={lid.id} value={lid.id}>
                            <Checkbox checked={value?.includes(lid.id)}/>
                            <ListItemText primary={lid.name_uk}/>
                          </MenuItem>
                        );
                    })}
                  </Select>)
                }
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={12}>
              <StyledFormControlLabel
                labelPlacement="top"
                sx={{
                  alignItems: 'flex-start',
                }}
                // @ts-ignore
                label={t("images.addImages")}
                control={
                  <CustomImageField />
                }
              />
        </Grid>
      </Grid>
      <ButtonLoadingWrapper isLoading={isLoading}>
        <Button
          variant="contained"
          type="submit"
          fullWidth
          size="large"
          sx={{
            background: '#373737',
            '&:hover': {
              background: '#666',
            }
          }}
        >
{/* @ts-ignore */}
          {t("createTray.submit")}
        </Button>
      </ButtonLoadingWrapper>
    </Box>
  )
}
export default CreateTrayForm