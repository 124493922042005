import { Box, IconButton } from "@mui/material";
import { StyledMenuWrapper, StyledNavLink } from "./style";
import { PermissionsUser, RouteUrls } from "../../../interfaces/enum";
import FolderSharedIcon from "@mui/icons-material/FolderShared";
import GroupIcon from "@mui/icons-material/Group";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTranslation } from "react-i18next";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import CollectionsBookmarkIcon from "@mui/icons-material/CollectionsBookmark";
import TakeoutDiningIcon from "@mui/icons-material/TakeoutDining";
import { useState } from "react";
import usePermissions from "../../../hooks/usePermission";
import PrecisionManufacturingIcon from "@mui/icons-material/PrecisionManufacturing";
import AllInboxIcon from "@mui/icons-material/AllInbox";
import ConstructionIcon from "@mui/icons-material/Construction";
import RecommendIcon from "@mui/icons-material/Recommend";
import RestoreIcon from "@mui/icons-material/Restore";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import { useHelperStore } from "../../../store/helperStore";

const Menu = () => {
  const { t } = useTranslation();
  const { hasPermission, isUser } = usePermissions();
  const isOpen = useHelperStore((state) => state.menuOpen);
  const setIsOpen = useHelperStore((state) => state.setMenuOpen);
  const media = useMediaQuery("(max-width: 600px)");

  const handleToggler = () => {
    setIsOpen(!isOpen);
  };

  return (
    <StyledMenuWrapper isOpen={isOpen}>
      <Box
        component={"img"}
        src={"/logo_footer.png"}
        sx={{
          height: "45px",
          visibility: isOpen ? "visible" : "hidden",
        }}
      />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "10px",
          flexGrow: 1,
        }}
      >
        {!media && (
          <>
          {/* @ts-ignore */}
            <StyledNavLink to={RouteUrls.PROJECTS} title={t("header.projects")}>
              <FolderSharedIcon />
              {/* @ts-ignore */}
              {isOpen && t("header.projects")}
            </StyledNavLink>
            {hasPermission(PermissionsUser.userList) && (
              // @ts-ignore
              <StyledNavLink to={RouteUrls.USERS} title={t("header.users")}>
                <GroupIcon />
                {/* @ts-ignore */}
                {isOpen && t("header.users")}
              </StyledNavLink>
            )}
            {hasPermission(PermissionsUser.userList) && (
              // @ts-ignore
              <StyledNavLink to={RouteUrls.ADMINS} title={t("header.admins")}>
                <AdminPanelSettingsIcon />
                {/* @ts-ignore */}
                {isOpen && t("header.admins")}
              </StyledNavLink>
            )}
            <StyledNavLink
              to={RouteUrls.HAND_BOOKS}
              // @ts-ignore
              title={t("header.handBooks")}
            >
              <CollectionsBookmarkIcon />
              {/* @ts-ignore */}
              {isOpen && t("header.handBooks")}
            </StyledNavLink>
            {!isUser && (
              <StyledNavLink
                to={RouteUrls.COATINGS}
                // @ts-ignore
                title={t("header.coatings")}
              >
                <ConstructionIcon />
                {/* @ts-ignore */}
                {isOpen && t("header.coatings")}
              </StyledNavLink>
            )}
            {!isUser && (
              // @ts-ignore
              <StyledNavLink to={RouteUrls.LID} title={t("header.lids")}>
                <AllInboxIcon />
                {/* @ts-ignore */}
                {isOpen && t("header.lids")}
              </StyledNavLink>
            )}
            {!isUser && (
              <StyledNavLink
                to={RouteUrls.METAL_PRODUCT}
                // @ts-ignore
                title={t("header.metalProducts")}
              >
                <PrecisionManufacturingIcon />
                {/* @ts-ignore */}
                {isOpen && t("header.metalProducts")}
              </StyledNavLink>
            )}
            {!isUser && (
              // @ts-ignore
              <StyledNavLink to={RouteUrls.TRAY} title={t("header.trays")}>
                <TakeoutDiningIcon />
                {/* @ts-ignore */}
                {isOpen && t("header.trays")}
              </StyledNavLink>
            )}
            {!isUser && (
              <StyledNavLink
                to={RouteUrls.RECOMMENDATIONS}
                // @ts-ignore
                title={t("header.recommendations")}
              >
                <RecommendIcon />
                {/* @ts-ignore */}
                {isOpen && t("header.recommendations")}
              </StyledNavLink>
            )}
            {hasPermission(PermissionsUser.fileHistory) && (
              // @ts-ignore
              <StyledNavLink to={RouteUrls.FILES} title={t("header.files")}>
                <RestoreIcon />
                {/* @ts-ignore */}
                {isOpen && t("header.files")}
              </StyledNavLink>
            )}
            {hasPermission(PermissionsUser.imageList) && (
              // @ts-ignore
              <StyledNavLink to={RouteUrls.IMAGES} title={t("header.images")}>
                <CameraAltIcon />
                {/* @ts-ignore */}
                {isOpen && t("header.images")}
              </StyledNavLink>
            )}
          </>
        )}
      </Box>
      <IconButton
        onClick={handleToggler}
        sx={{
          position: "absolute",
          bottom: "60px",
          right: "-13px",
          color: "#000",
          background: "#242531",
          transition: "all 0.4s ease-in-out",
          "&:hover": {
            transition: "all 0.4s ease-in-out",
            backgroundColor: "#fff",
            "& svg": {
              color: "#242531",
            },
          },
          "& svg": {
            height: "1.5em",
            width: "1.5em",
            color: "#ccc",
          },
        }}
      >
        <KeyboardBackspaceIcon
          sx={{
            transition: "all 0.2s ease",
            transform: !isOpen ? "rotate(180deg)" : "rotate(0deg)",
          }}
        />
      </IconButton>
    </StyledMenuWrapper>
  );
};

export default Menu;
