import {Box, TableBody, TableRow, Typography} from "@mui/material";
import {useTranslation} from "react-i18next";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import {StyledActionButton, StyledTableCell} from "./style";
import useAsyncWrapper from "../../../../../../hooks/useAsyncWrapper";
import ConfirmDelete from "../../../../Modals/ConfirmDelete";
import {useState} from "react";
import usePermission from "../../../../../../hooks/usePermission";
import {PermissionsUser} from "../../../../../../interfaces/enum";
import useToast from "../../../../../../hooks/useToast";
// import EditCoating from "../../../../Modals/EditCoating";
import {useCoatingsStore} from "../../../../../../store/coatingsStore";
import EditCoating from "../../../../Modals/EditCoating";


interface IProps {
  data: any[]
}
const CoatingTableBody = ({ data }: IProps) => {
  const { t } = useTranslation()
  const { errorToast, successToast} = useToast()
  const [id, setId] = useState(0);
  const { hasPermission } = usePermission()

  const [open, setOpen] = useState({
    delete: false,
    edit: false
  });

  const handleOpenDelete = (id: number) => {
    setId(id)

    setOpen({
      ...open,
      delete: true
    })
  }
  const handleOpenEdit = (id: number) => {
    setId(id)
    setOpen({
      ...open,
      edit: true
    })
  }
  const handleCloseDelete = () => {
    setOpen({
      ...open,
      delete: false
    })
    setTimeout(() => setId(0), 200)
  }
  const handleCloseEdit = () => {
    setOpen({
      ...open,
      edit: false
    })
    setId(0)
  }

  const deleteCoating = useCoatingsStore(state => state.deleteCoating);
  const deleteCoatingHandler = useAsyncWrapper(deleteCoating);

  const handleDeleteUser = async (id: number) => {
    const {error} = await deleteCoatingHandler([id])
    if(!error) {
      // @ts-ignore
      successToast(t('deleteCoating.success'))
      return;
    }
    if(error?.response?.status === 430) {
      // @ts-ignore
      errorToast(t('deleteCoating.error430'), t('deleteCoating.error430add'))
      return;
    }

    // @ts-ignore
    errorToast(t('deleteCoating.error'));
  }

  const paramaters = data?.find((row) => row?.id === id)?.count?.model_parameters + data?.find((row) => row?.id === id)?.count?.recommendations


  return (<TableBody>

    {data
      ?.map((row) => {
        const paramaters = row?.count?.model_parameters + row?.count?.recommendations
       return (
          <TableRow
            hover
            key={row.id}
            sx={{
              '&.MuiTableRow-hover:hover': {
                opacity: 0.8,
              },
              '& .MuiTableCell-root': {
                backgroundColor: row.blocked ? '#d32f2f' : '#D8D9DA',
                color: row.blocked ? 'white' : 'black'
              }
            }}>
            <StyledTableCell>
              <Typography>{row.id}</Typography>
            </StyledTableCell>
            <StyledTableCell>
              <Typography>{row.code}</Typography>
            </StyledTableCell>
            <StyledTableCell>
              <Typography>{row.name_uk}</Typography>
            </StyledTableCell>
            <StyledTableCell>
              <Typography>{row.name_en}</Typography>
            </StyledTableCell>
            <StyledTableCell>
              <Box sx={{
                display: 'flex',
                gap: '5px'
              }}>
                <StyledActionButton disabled={!hasPermission(PermissionsUser.coatingUpdate)} onClick={() => handleOpenEdit(row.id)}>
                  <EditIcon color={'warning'} />
                </StyledActionButton>
                <StyledActionButton disabled={!hasPermission(PermissionsUser.coatingDestroy)} onClick={() => handleOpenDelete(row.id)}>
                  <DeleteIcon color={'error'}/>
                </StyledActionButton>
              </Box>
            </StyledTableCell>
          </TableRow>
        );
      })}
    <ConfirmDelete
    // @ts-ignore
      text={!paramaters ? t('deleteCoating.submissionText') : t('deleteCoating.errorCoatingUsing', {value: paramaters })}
      // @ts-ignore
      title={t('deleteCoating.title')}
      handleSubmit={() => handleDeleteUser(id)}
      handleClose={handleCloseDelete}
      open={open.delete}
    />
    <EditCoating
      id={id}
      open={open.edit}
      handleClose={handleCloseEdit}
    />
  </TableBody>)
}

export default CoatingTableBody