import {Box, Button} from "@mui/material";
import Description from "./components/Description";
import ChoosenFooter from "./components/ChoosenFooter";
import {useTranslation} from "react-i18next";
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import {useProjectStore} from "../../../../../../store/projectStore";
import {useProjectsStore} from "../../../../../../store/projectsStore";
import useAsyncWrapper from "../../../../../../hooks/useAsyncWrapper";
import removeNull from "../../../../../../utils/removeNull";
import useToast from "../../../../../../hooks/useToast";
import EditIcon from '@mui/icons-material/Edit';
import React, {memo} from "react";
import ImageCarousel from "../../ImageCarousel";

interface IProps {
  closeModal?: () => void;
}
const ChoosenItem = ({ closeModal = () => {} } : IProps) => {
  const { t } = useTranslation()
  const { errorToast, successToast } = useToast()

  const projectLength = useProjectStore(state => state.projectLength)
  const isLid = useProjectStore(state => state.isLid)
  const with_partition = useProjectStore(state => state.with_partition)
  const trayQuantity = useProjectStore(state => state.trayQuantity)
  const choosenItem = useProjectStore(state => state.choosenItem)
  const currentPosition = useProjectStore(state => state.currentPosition)
  const images = useProjectStore(state => state.currentTray)?.images ?? []


  const currentProject = useProjectsStore(state => state.currentProject)
  const addPosition = useProjectStore(state => state.addPosition)
  const editPosition = useProjectStore(state => state.editPosition)
  const setCurrentPosition = useProjectStore(state => state.setCurrentPosition)
  const getPositionsByProject = useProjectStore(state => state.getPositionsByProject)
  const loadingCart = useProjectStore(state => state.loadingCart)
  const choosenLid = useProjectStore(state => state.choosenLid)
  const choosenPartition = useProjectStore(state => state.choosenPartition)

  const addPositionHandler = useAsyncWrapper(addPosition)
  const editPositionHandler = useAsyncWrapper(editPosition)
  const getPositionsByProjectHandler = useAsyncWrapper(getPositionsByProject)

  const handleAdd = async() => {
      const payload = {
        project_id: currentProject?.id,
        tray_model_parameter_id: choosenItem.id,
        quantity: trayQuantity,
        with_lid: isLid,
        length: projectLength,
        with_partition: with_partition,
        ...(isLid ? { lid_model_parameter: choosenLid } : {}),
        ...(with_partition ? { partition_model_parameter: choosenPartition } : {})

      }
      const { data, error } = await addPositionHandler([removeNull(payload)])
      if(!error) {
        await getPositionsByProjectHandler([currentProject?.id])
        // @ts-ignore
        successToast(t('project.positionAddSuccess'))
        closeModal()
        return
      }
      // @ts-ignore
      errorToast(t('project.positionAddFailed'))
  }
  const handleEdit = async() => {
      const payload = {
        project_id: currentProject?.id,
        tray_model_parameter_id: choosenItem.id,
        quantity: trayQuantity,
        with_lid: isLid,
        length: projectLength,
        with_partition: with_partition,
        ...(isLid ? { lid_model_parameter: choosenLid } : {}),
        ...(with_partition ? { partition_model_parameter: choosenPartition } : {})

      }
      const { data, error } = await editPositionHandler([removeNull(payload), currentPosition?.id])

      setCurrentPosition(null)
      if(!error) {
        // @ts-ignore
        successToast(t('project.positionEditSuccess'));
        closeModal()
        return
      }
      // @ts-ignore
      errorToast(t('project.positionEditFailed'))
  }



  return (
    <Box sx={{
      border: '1px solid #E5E5E5',
      padding: '10px',
      borderRadius: '10px',
      height: '100%',
      overflowY: 'auto',
      display: 'flex',
      flexDirection: 'column',
    }}>
      <Box sx={{
        display: 'flex',
        gap: '20px'
      }}>
        <ImageCarousel images={images}/>
        <Description />
      </Box>
      <ChoosenFooter />
      <Box sx={{
        height: '100%'
      }} />
      <Button
        color={'error'}
        onClick={currentPosition ? handleEdit : handleAdd}
        disabled={loadingCart}
        sx={{
          padding: '8px 10px',
          fontSize: '12px',
          fontWeight: '600',
          borderRadius: '8px',
          lineHeight: '1.5em',
          alignSelf: 'flex-end',
          marginTop: '5px'
        }}
        variant={'contained'}
        startIcon={currentPosition ? <EditIcon /> : <AddShoppingCartIcon />}>
          {/* @ts-ignore */}
        {t(currentPosition ? 'project.editCart' : 'project.addToCart')}
      </Button>
    </Box>
  )
}

export default memo(ChoosenItem)