import {Controller, useForm} from "react-hook-form";
import {ICreateLidForm, ICreateUserForm, IEditUserForm} from "../../../../../interfaces/general";
import {yupResolver} from "@hookform/resolvers/yup";
import {StyledFormControlLabel} from "./style";
import {Box, Button, Checkbox, Grid, ListItemText, MenuItem, OutlinedInput, Select, TextField} from "@mui/material";
import ButtonLoadingWrapper from "../../../../Wrappers/ButtonLoading";
import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import useAsyncWrapper from "../../../../../hooks/useAsyncWrapper";
import useToast from "hooks/useToast";

import {useLidsStore} from "../../../../../store/lidsStore";
import {getLidCreateValidationSchema} from "../../../../../validations/lids/createLid";
import {useCoatingsStore} from "../../../../../store/coatingsStore";
import {lidTypes, measurements, trayTypes} from "../../../../../constants";
import CustomImageField from "../../../CustomImageField";
import {useImagesStore} from "../../../../../store/imagesStore";


interface IProps {
  handleClose: () => void;
}
const initialFormState = {
  name_uk: '',
  name_en: '',
  article_prefix_2: '',
  article_prefix_1: '',
  type: "lid",
}

const CreateLidForm = ({ handleClose }: IProps) => {
  const { t } = useTranslation()
  const { errorToast, successToast } = useToast();

  const [isLoading, setIsLoading] = useState(false);

  const createLid = useLidsStore(state => state.createLid)

  const createLidHandler = useAsyncWrapper(createLid)
  const choosedImages = useImagesStore(state => state.modal.choosedImages)

  const schema = getLidCreateValidationSchema(t);

  const {handleSubmit, control, watch } = useForm<ICreateLidForm>({
    defaultValues: initialFormState,
    resolver: yupResolver(schema),
  });


  const handleCreateSubmit = async (body: ICreateLidForm) => {
    const payload = {
      ...body,
      image_ids: choosedImages,
    }
    setIsLoading(true)
    const { error, status } = await createLidHandler( [payload] )
    setIsLoading(false)

    if(!error) {
      // @ts-ignore
      successToast(t('createLid.success'))
      handleClose()
      return;
    }
    if (status === 400) {
      const errors = error?.response?.data?.message ?? [];
      if(error?.response?.data?.message.length > 0) {
        // @ts-ignore
        errors.forEach((err: any) => errorToast(t('error.error'), t('createLid.error.400.' + err?.field)))
      }

      return
    }
// @ts-ignore
    errorToast(t('createLid.error.default'))
  }


  return (
    <Box
      component={'form'}
      onSubmit={handleSubmit(handleCreateSubmit)}
      sx={{
        '@media (min-width: 600px)': {
          minWidth: '350px',
        }
      }}
    >
      <Grid
        container
        spacing={1}
        mb={2}
      >
        <Grid item xs={12} md={6}>
          <Controller
            name={"name_uk"}
            control={control}
            render={({
                       fieldState: {error},
                       field: {onChange, value, name},
                     }) => (
              <StyledFormControlLabel
                labelPlacement="top"
                sx={{
                  alignItems: 'flex-start'
                }}
                // @ts-ignore
                label={t("trays.name_uk")}
                control={
                  <TextField
                    fullWidth
                    onChange={onChange}
                    value={value}
                    error={error?.ref?.name === name}
                    helperText={error ? error.message : ' '}
                  />
                }
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Controller
            name={"name_en"}
            control={control}
            render={({
                       fieldState: {error},
                       field: {onChange, value, name},
                     }) => (
              <StyledFormControlLabel
                labelPlacement="top"
                sx={{
                  alignItems: 'flex-start'
                }}
                // @ts-ignore
                label={t("trays.name_en")}
                control={
                  <TextField
                    fullWidth
                    onChange={onChange}
                    value={value}
                    error={error?.ref?.name === name}
                    helperText={error ? error.message : ' '}
                  />
                }
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Controller
            name={"article_prefix_1"}
            control={control}
            render={({
                       fieldState: {error},
                       field: {onChange, value, name},
                     }) => (
              <StyledFormControlLabel
                labelPlacement="top"
                sx={{
                  alignItems: 'flex-start'
                }}
                // @ts-ignore
                label={t("trays.article_prefix_1")}
                control={
                  <TextField
                    fullWidth
                    onChange={onChange}
                    value={value}
                    error={error?.ref?.name === name}
                    helperText={error ? error.message : ' '}
                  />
                }
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Controller
            name={"article_prefix_2"}
            control={control}
            render={({
                       fieldState: {error},
                       field: {onChange, value, name},
                     }) => (
              <StyledFormControlLabel
                labelPlacement="top"
                sx={{
                  alignItems: 'flex-start'
                }}
                // @ts-ignore
                label={t("trays.article_prefix_2")}
                control={
                  <TextField
                    fullWidth
                    onChange={onChange}
                    value={value}
                    error={error?.ref?.name === name}
                    helperText={error ? error.message : ' '}
                  />
                }
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <Controller
            name={"type"}
            control={control}
            render={({
                       fieldState: {error},
                       field: {onChange, value, name},
                     }) => (
              <StyledFormControlLabel
                labelPlacement="top"
                sx={{
                  alignItems: 'flex-start'
                }}
                // @ts-ignore
                label={t("lids.type")}
                control={(
                  <Select
                    fullWidth
                    sx={{
                      padding: '0px',
                      '& .MuiSelect-select': {
                        padding: '10px 0 10px 10px',
                      }
                    }}
                    value={value}
                    onChange={onChange}
                    defaultValue={lidTypes[0]}
                    error={error?.ref?.name === name}
                  >
                    {lidTypes.map((lidType) => {
                      return (
                        <MenuItem key={lidType} value={lidType}>
                          {/* @ts-ignore */}
                          {t('lids.'+lidType)}
                        </MenuItem>
                      );
                    })}
                  </Select>)
                }
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <StyledFormControlLabel
            labelPlacement="top"
            sx={{
              alignItems: 'flex-start',
            }}
            // @ts-ignore
            label={t("images.addImages")}
            control={
              <CustomImageField />
            }
          />
        </Grid>
      </Grid>
      <ButtonLoadingWrapper isLoading={isLoading}>
        <Button
          variant="contained"
          type="submit"
          fullWidth
          size="large"
          sx={{
            background: '#373737',
            '&:hover': {
              background: '#666',
            }
          }}
        >
{/* @ts-ignore */}
          {t("createLid.submit")}
        </Button>
      </ButtonLoadingWrapper>
    </Box>
  )
}
export default CreateLidForm