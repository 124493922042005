import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import CloseSvg from "../../../../assets/svgs/common/CloseSvg";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useProjectsStore } from "../../../../store/projectsStore";
import { useProjectStore } from "../../../../store/projectStore";
import useAsyncWrapper from "../../../../hooks/useAsyncWrapper";
import OpenInFullIcon from "@mui/icons-material/OpenInFull";
import CloseFullscreenIcon from "@mui/icons-material/CloseFullscreen";
import { Installation } from "../../../../interfaces/general";
import InternalExternal from "./components/InternalExternal";
import RecommendationChoose from "./components/RecommendationChoose";
import TableLoading from "../../TableLoading";

interface IProps {
  isOpen: boolean;
  handleClose: () => void;
}

const RecommendationModal = ({ isOpen, handleClose }: IProps) => {
  const { t } = useTranslation();

  const setRecommendation = useProjectStore((state) => state.setRecommendation);
  const recommendation = useProjectStore((state) => state.recommendation);
  const { choosenRec, installation, recData, loadingRecommendation } =
    recommendation;

  useEffect(() => {
    if (isOpen) {
      setRecommendation({
        ...recommendation,
        installation: null,
      });
    }
  }, [isOpen]);

  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      sx={{
        "& .MuiBackdrop-root": {
          backgroundColor: "rgba(0,0,0,0.1)", // Try to remove this to see the result
        },
        "& .MuiDialog-paper": {
          boxShadow: "none",
          maxWidth: "500px",
          width: "100%",
          padding: "20px",
        },
      }}
    >
      <DialogTitle
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          fontWeight: 600,
          gap: "20px",
          color: "#373737",
        }}
      >
        {t(
          // @ts-ignore
          !installation
            ? "project.recommendation.placementTitle"
            : "project.recommendation.titleChooseRec"
        )}
        <Box
          onClick={handleClose}
          sx={{
            height: "23px",
          }}
        >
          <CloseSvg />
        </Box>
      </DialogTitle>

      <DialogContent
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "25px",
        }}
      >
        {loadingRecommendation ? (
          <TableLoading />
        ) : !installation ? (
          <InternalExternal />
        ) : (
          <RecommendationChoose handleClose={handleClose} />
        )}
      </DialogContent>
    </Dialog>
  );
};

export default RecommendationModal;
