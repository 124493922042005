import {Box, TableBody, TableRow, Typography} from "@mui/material";
import {useTranslation} from "react-i18next";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import {StyledActionButton, StyledTableCell} from "./style";
import useAsyncWrapper from "../../../../../../hooks/useAsyncWrapper";
import ConfirmDelete from "../../../../Modals/ConfirmDelete";
import {useState} from "react";
import usePermission from "../../../../../../hooks/usePermission";
import {PermissionsUser} from "../../../../../../interfaces/enum";
import useToast from "../../../../../../hooks/useToast";
import EditTray from "../../../../Modals/EditTray";
import {useTraysStore} from "../../../../../../store/traysStore";


interface IProps {
  data: any[]
}
const MetalProductTableBody = ({ data }: IProps) => {
  const { t, i18n } = useTranslation()
  const { errorToast, successToast} = useToast()
  const [id, setId] = useState(0);
  const { hasPermission } = usePermission()

  const [open, setOpen] = useState({
    delete: false,
    edit: false
  });

  const handleOpenDelete = (id: number) => {
    setId(id)

    setOpen({
      ...open,
      delete: true
    })
  }
  const handleOpenEdit = (id: number) => {
    setId(id)
    setOpen({
      ...open,
      edit: true
    })
  }
  const handleCloseDelete = () => {
    setOpen({
      ...open,
      delete: false
    })
    setId(0)
  }
  const handleCloseEdit = () => {
    setOpen({
      ...open,
      edit: false
    })
    setId(0)
  }

  const deleteTray = useTraysStore(state => state.deleteTray);
  const deleteTrayHandler = useAsyncWrapper(deleteTray);

  const handleDeleteUser = async (id: number) => {
    const {error} = await deleteTrayHandler([id])
    if(!error) {
      // @ts-ignore
      successToast(t('deleteTray.success'))
      return;
    }
    // @ts-ignore
    errorToast(t('deleteTray.error'));  }


  return (<TableBody>

    {data
      ?.map((row) => {
        return (
          <TableRow
            hover
            role="checkbox"
            tabIndex={-1}
            key={row.id}
            sx={{
              '&.MuiTableRow-hover:hover': {
                opacity: 0.8,
              },
              '& .MuiTableCell-root': {
                backgroundColor: row.blocked ? '#d32f2f' : '#D8D9DA',
                color: row.blocked ? 'white' : 'black'
              }
            }}>
            <StyledTableCell>
              <Typography>{row.id}</Typography>
            </StyledTableCell>
            <StyledTableCell>
              <Typography>{row.name_en}</Typography>
            </StyledTableCell>
            <StyledTableCell>
              <Typography>{row.name_uk}</Typography>
            </StyledTableCell>
            <StyledTableCell>
              <Typography>{row.article_prefix_1}</Typography>
            </StyledTableCell>
            <StyledTableCell>
              <Typography>{row.article_prefix_2}</Typography>
            </StyledTableCell>
            <StyledTableCell>
              {/* @ts-ignore */}
              <Typography>{t('trays.' + row?.type)}</Typography>
            </StyledTableCell>
            {/*<StyledTableCell>*/}
            {/*  <Typography>{arrayJoin(row?.coatings?.map((el : any) => el.code))}</Typography>*/}
            {/*</StyledTableCell>*/}
            {/*<StyledTableCell>*/}
            {/*  <Typography>{row.unit_of_measurement}</Typography>*/}
            {/*</StyledTableCell>*/}
            {/*<StyledTableCell>*/}
            {/*  <Typography*/}
            {/*    sx={{*/}
            {/*      display: '-webkit-box',*/}
            {/*      WebkitLineClamp: 2,*/}
            {/*      WebkitBoxOrient: 'vertical',*/}
            {/*      overflow: 'hidden',*/}
            {/*    }}>{arrayJoin(row.width)}</Typography>*/}
            {/*</StyledTableCell>*/}
            {/*<StyledTableCell>*/}
            {/*  <Typography*/}
            {/*    sx={{*/}
            {/*      display: '-webkit-box',*/}
            {/*      WebkitLineClamp: 2,*/}
            {/*      WebkitBoxOrient: 'vertical',*/}
            {/*      overflow: 'hidden',*/}
            {/*    }}>{arrayJoin(row.height)}</Typography>*/}
            {/*</StyledTableCell>*/}
            {/*<StyledTableCell>*/}
            {/*  <Typography*/}
            {/*    sx={{*/}
            {/*      display: '-webkit-box',*/}
            {/*      WebkitLineClamp: 2,*/}
            {/*      WebkitBoxOrient: 'vertical',*/}
            {/*      overflow: 'hidden',*/}
            {/*    }}>{arrayJoin(row.thickness)}</Typography>*/}
            {/*</StyledTableCell>*/}
            {/*<StyledTableCell>*/}
            {/*  <Typography sx={{*/}
            {/*    display: '-webkit-box',*/}
            {/*    WebkitLineClamp: 2,*/}
            {/*    WebkitBoxOrient: 'vertical',*/}
            {/*    overflow: 'hidden',*/}
            {/*  }}>{arrayJoin(row.length)}</Typography>*/}
            {/*</StyledTableCell>*/}
            {/*<StyledTableCell>*/}
            {/*  <Typography sx={{*/}
            {/*    display: '-webkit-box',*/}
            {/*    WebkitLineClamp: 2,*/}
            {/*    WebkitBoxOrient: 'vertical',*/}
            {/*    overflow: 'hidden',*/}
            {/*  }}>{arrayJoin(row.weight)}</Typography>*/}
            {/*</StyledTableCell>*/}
            {/*<StyledTableCell>*/}
            {/*  <Typography sx={{*/}
            {/*      display: '-webkit-box',*/}
            {/*      WebkitLineClamp: 2,*/}
            {/*      WebkitBoxOrient: 'vertical',*/}
            {/*      overflow: 'hidden',*/}
            {/*  }}>{arrayJoin(row.angle)}</Typography>*/}
            {/*</StyledTableCell>*/}
            <StyledTableCell>
              <Box sx={{
                display: 'flex',
                gap: '5px'
              }}>
                <StyledActionButton disabled={!hasPermission(PermissionsUser.trayUpdate)} onClick={() => handleOpenEdit(row.id)}>
                  <EditIcon color={'warning'} />
                </StyledActionButton>
                <StyledActionButton disabled={!hasPermission(PermissionsUser.trayDestroy)} onClick={() => handleOpenDelete(row.id)}>
                  <DeleteIcon color={'error'}/>
                </StyledActionButton>
              </Box>
            </StyledTableCell>
          </TableRow>
        );
      })}
    <ConfirmDelete
    // @ts-ignore
      text={t('deleteTray.submissionText')}
      // @ts-ignore
      title={t('deleteTray.title')}
      handleSubmit={() => handleDeleteUser(id)}
      handleClose={handleCloseDelete}
      open={open.delete}
    />
    <EditTray
      id={id}
      open={open.edit}
      handleClose={handleCloseEdit}
    />
  </TableBody>)
}

export default MetalProductTableBody