import {Box, IconButton, Snackbar, Typography} from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import React, {useContext, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import DownloadIcon from '@mui/icons-material/Download';
import {env} from "../../../constants";
import SocketContext from "../../../context/socket";
import ReplayIcon from '@mui/icons-material/Replay';

interface IProps {

}
const SuccessSnackbar = ({ }: IProps ) => {
  const { t } = useTranslation()
  const context: any = useContext(SocketContext)

  const [open, setOpen] = useState(false)

  const { action, item, type } = context?.messageSuccess!;

  const handleClose = () => {
    setOpen(false)
  }

  function handleDownload(url: string, name: string) {
    const link = document.createElement("a");
    link.download = name;
    link.href = env.REACT_APP_DEV_BASE_API_URL +  url;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
  function handleReload() {
    window.location.reload()
  }


  useEffect(() => {
    if(action && item && type && !open) {
      setOpen(true)
    }
  }, [action, item?.id, type])


  return <Snackbar
    open={open}
    anchorOrigin={{
      vertical: 'top', horizontal: 'left'
    }}
    // onClose={handleClose}
    sx={{
      '& .MuiSnackbarContent-action': {
        marginLeft: '0',
        paddingLeft: '0',
        width: '100%'
      },
    }}
    action={<Box sx={{
      display: 'flex',
      flexDirection: 'column',
      gap: '10px',
      maxWidth: '300px',
      width: '100%'
    }}>


      <Box sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
      }}>
        <Typography variant={'h6'}>
          {/* @ts-ignore */}
          {t('common.notification')}
        </Typography>
        <IconButton
          aria-label="close"
          color="inherit"
          sx={{ p: 0.5 }}
          onClick={handleClose}
        >
          <CloseIcon />
        </IconButton>
      </Box>


      <Box sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
      }}>
        <Typography variant={'body2'}>
          {/* @ts-ignore */}
          {t(action === 'import' ? `import.success.${type}` : `export.success.${type}`)}
        </Typography>
        {item && action === 'export' ? <IconButton
            onClick={() => handleDownload(item?.file_url, item?.file_name)}
            sx={{
              color: 'red',
              paddingRight: '3px'
            }}
        >
          <DownloadIcon/>
        </IconButton> : <IconButton
          onClick={handleReload}
          sx={{
            color: 'red',
            paddingRight: '3px'
          }}
        >
          <ReplayIcon />
        </IconButton>}
      </Box>


    </Box>}
  />
}
export default SuccessSnackbar