import {
  Box,
  Button,
  FormControl,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  useMediaQuery,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import React, { useEffect, useState } from "react";
import { useUsersTableStore } from "../../../../../../store/usersTableStore";
import { useTranslation } from "react-i18next";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
const Filter = () => {
  const { t } = useTranslation();
  const media = useMediaQuery("(max-width: 550px)");
  const [isShort, setIsShort] = useState(!media);

  const filter = useUsersTableStore((state) => state.filter);
  const setFilter = useUsersTableStore((state) => state.setFilter);
  const resetFilter = useUsersTableStore((state) => state.resetFilter);

  const handleSearch = (e: any, field: string) => {
    setFilter({
      ...filter,
      filters: filter.filters?.find((el) => el.field_name === field)
        ? filter.filters?.map((el) =>
            el.field_name === field ? { ...el, value: e.target.value } : el
          )
        : [
            ...filter.filters,
            {
              field_name: field,
              rule: "contains",
              value: e.target.value,
            },
          ],
    });
  };

  const getValue = (field: string) => {
    return filter?.filters?.find((el) => el?.field_name === field)?.value ?? "";
  };

  useEffect(() => {
    return () => {
      resetFilter();
    };
  }, []);
  useEffect(() => {
    setIsShort(media);
  }, [media]);
  return (
    <>
      <Box
        sx={{
          display: "flex",
          gap: "10px",
          flexWrap: "wrap",
          alignItems: "flex-end",
        }}
      >
        <TextField
          // @ts-ignore
          label={t("common.searchBy.title") + " " + t("common.searchBy.email")}
          type="search"
          variant="standard"
          onChange={(e) => handleSearch(e, "email")}
          sx={{
            minWidth: "250px",
          }}
          value={getValue("email")}
          InputProps={{
            endAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
        <TextField
          label={
            // @ts-ignore
            t("common.searchBy.title") + " " + t("common.searchBy.phone_number")
          }
          type="search"
          variant="standard"
          onChange={(e) => handleSearch(e, "phone_number")}
          sx={{
            minWidth: "250px",
          }}
          value={getValue("phone_number")}
          InputProps={{
            endAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
        {!isShort && (
          <>
            <TextField
              label={
                // @ts-ignore
                t("common.searchBy.title") +
                " " +
                // @ts-ignore
                t("common.searchBy.first_name")
              }
              type="search"
              variant="standard"
              onChange={(e) => handleSearch(e, "first_name")}
              sx={{
                minWidth: "250px",
              }}
              value={getValue("first_name")}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
            <TextField
              label={
                // @ts-ignore
                t("common.searchBy.title") +
                " " +
                // @ts-ignore
                t("common.searchBy.last_name")
              }
              type="search"
              variant="standard"
              onChange={(e) => handleSearch(e, "last_name")}
              sx={{
                minWidth: "250px",
              }}
              value={getValue("last_name")}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
            <TextField
              label={
                // @ts-ignore
                t("common.searchBy.title") + " " + t("common.searchBy.company")
              }
              type="search"
              variant="standard"
              onChange={(e) => handleSearch(e, "company")}
              sx={{
                minWidth: "250px",
              }}
              value={getValue("company")}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
            <FormControl
              variant="filled"
              sx={{
                minWidth: 120,
                "& .MuiFormLabel-root": {
                  top: typeof getValue("active") !== "string" ? "0" : "10px",
                },
              }}
            >
              <InputLabel id="demo-simple-select-filled-label">
                {/* @ts-ignore */}
                {t("common.searchBy.status")}
              </InputLabel>
              <Select
                sx={{
                  padding: "0px",
                  maxHeight: "40px",
                  minWidth: "210px",
                  "& .MuiSelect-select": {
                    padding: "10px 0 10px 10px",
                  },
                }}
                variant={"standard"}
                value={getValue("active")}
                labelId="filter"
                // @ts-ignore
                placeholder={t("common.fieldForSearch")}
                onChange={(e) => handleSearch(e, "active")}
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                {/* @ts-ignore */}
                <MenuItem value={1}>{t("usersTable.active")}</MenuItem>
                {/* @ts-ignore */}
                <MenuItem value={0}>{t("usersTable.nonActive")}</MenuItem>
              </Select>
            </FormControl>
            <FormControl
              variant="filled"
              sx={{
                minWidth: 120,
                "& .MuiFormLabel-root": {
                  top: typeof getValue("blocked") !== "string" ? "0" : "10px",
                },
              }}
            >
              <InputLabel id="demo-simple-select-filled-label">
                {/* @ts-ignore */}
                {t("common.searchBy.blocked")}
              </InputLabel>
              <Select
                sx={{
                  padding: "0px",
                  maxHeight: "40px",
                  minWidth: "210px",
                  "& .MuiSelect-select": {
                    padding: "10px 0 10px 10px",
                  },
                }}
                variant={"standard"}
                value={getValue("blocked")}
                labelId="filter"
                // @ts-ignore
                placeholder={t("common.fieldForSearch")}
                onChange={(e) => handleSearch(e, "blocked")}
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                {/* @ts-ignore */}
                <MenuItem value={1}>{t("usersTable.blocked")}</MenuItem>
                {/* @ts-ignore */}
                <MenuItem value={0}>{t("usersTable.nonBlocked")}</MenuItem>
              </Select>
            </FormControl>
          </>
        )}
        <br />
      </Box>
      {media && (
        <Button
          onClick={() => setIsShort((prev) => !prev)}
          variant={"outlined"}
          endIcon={
            <ExpandMoreIcon
              sx={{ transform: !isShort ? "rotate(180deg)" : "rotate(0deg)" }}
            />
          }
          color={"warning"}
          sx={{
            transition: "all 0.3s ease",
            "& svg": {
              transition: "all 0.3s ease",
            },
          }}
        >
          {/* @ts-ignore */}
          {isShort ? t("common.showMore") : t("common.showLess")}
        </Button>
      )}
    </>
  );
};
export default Filter;
