import { memo } from "react";
import { Box, Button, Tooltip, useMediaQuery } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useProjectStore } from "../../../../../store/projectStore";

const ProjectNav = () => {
  const { t } = useTranslation();

  const media = useMediaQuery("(max-width: 600px)");

  const accessoryView = useProjectStore((state) => state.accessoryView);
  const setAccessoryView = useProjectStore((state) => state.setAccessoryView);
  const positions = useProjectStore((state) => state.project_positions);

  const handleAccessoryView = () => {
    setAccessoryView(!accessoryView);
  };
  return !media ? (
    <Box
      sx={{
        display: "flex",
        gap: "10px",
      }}
    >
      <Button
        color={"error"}
        variant={"contained"}
        onClick={handleAccessoryView}
        disabled={!accessoryView}
        sx={{
          minWidth: "120px",
          borderBottom: accessoryView ? "none" : "2px solid #DA281A",
          "&.Mui-disabled": {
            color: !accessoryView ? "#DA281A" : "white",
            pointerEvents: "auto",
          },
        }}
      >
        {/* @ts-ignore */}
        {t("project.trayView")}
      </Button>
      <Tooltip
        title={t(
          // @ts-ignore
          !accessoryView
            ? !positions.length
              ? "project.disabledAccessories"
              : "project.accessoryView"
            : "project.accessoryView"
        )}
      >
        <Button
          color={"error"}
          variant={"contained"}
          onClick={handleAccessoryView}
          disabled={!positions.length || accessoryView}
          sx={{
            minWidth: "120px",
            borderBottom: !accessoryView ? "none" : "2px solid #DA281A",
            "&.Mui-disabled": {
              color: accessoryView ? "#DA281A" : "white",
              pointerEvents: "auto",
            },
          }}
        >
          {/* @ts-ignore */}
          {t("project.accessoryView")}
        </Button>
      </Tooltip>
    </Box>
  ) : (
    <Tooltip
      title={t(
        // @ts-ignore
        !accessoryView
          ? !positions.length
            ? "project.disabledAccessories"
            : "project.accessoryView"
          : "project.trayView"
      )}
    >
      <Button
        color={"error"}
        variant={"contained"}
        onClick={handleAccessoryView}
        disabled={!positions.length}
        sx={{
          minWidth: "120px",
          "&.Mui-disabled": {
            pointerEvents: "auto",
          },
        }}
      >
        {/* @ts-ignore */}
        {t(!accessoryView ? "project.accessoryView" : "project.trayView")}
      </Button>
    </Tooltip>
  );
};
export default memo(ProjectNav);
