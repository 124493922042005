import { TFunction } from "i18next";
import * as Yup from "yup";

export const getResetPasswordValidationSchema = (t: (key: string) => string) =>
  Yup.object().shape({
    password_old: Yup.string()
      .required(t("error.required"))
      .min(6, t("signUp.error.minLength"))
      .max(20, t("signUp.error.maxLength")),
    password: Yup.string()
      .required(t("error.required"))
      .min(6, t("signUp.error.minLength"))
      .max(20, t("signUp.error.maxLength")),
    passwordConfirm: Yup.string().oneOf(
      [Yup.ref("password"), null as any],
      t("signUp.error.passwordConfirm")
    ),
  });
