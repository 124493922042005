import {
  Box,
  Button,
  IconButton,
  MobileStepper,
  Paper,
  Typography,
} from "@mui/material";
import React, { memo, useEffect, useState } from "react";
import { useProjectStore } from "../../../../../../../../store/projectStore";
import useLang from "../../../../../../../../hooks/useLang";
import { useTranslation } from "react-i18next";

const Description = () => {
  const { getTranslate } = useLang();
  const { t } = useTranslation();

  const currentTray = useProjectStore((state) => state.currentTray);
  const choosenItem = useProjectStore((state) => state.choosenItem);

  return (
    <Box
      sx={{
        width: "100%",
      }}
    >
      <Typography variant={"h6"}>
        {getTranslate("name", choosenItem?.tray ?? {})}
      </Typography>

      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
        }}
      >
        <Box
          sx={{
            marginRight: "20px",
          }}
        >
          <Typography
            variant={"body2"}
            sx={{
              "& span": {
                color: "#DA281A",
                fontWeight: "bold",
              },
            }}
          >
            {/* @ts-ignore */}
            <span>{t("common.width")}:</span> {choosenItem?.width}
            <b>
              {t(
                // @ts-ignore
                `unit_of_measurement.${choosenItem?.unit_of_measurement ?? ""}`
              )}
            </b>
          </Typography>
          <Typography
            variant={"body2"}
            sx={{
              "& span": {
                color: "#DA281A",
                fontWeight: "bold",
              },
            }}
          >
            {/* @ts-ignore */}
            <span>{t("common.height")}:</span> {choosenItem?.height}
            <b>
              {t(
                // @ts-ignore
                `unit_of_measurement.${choosenItem?.unit_of_measurement ?? ""}`
              )}
            </b>
          </Typography>
          <Typography
            variant={"body2"}
            sx={{
              "& span": {
                color: "#DA281A",
                fontWeight: "bold",
              },
            }}
          >
            {/* @ts-ignore */}
            <span>{t("common.length")}:</span> {choosenItem?.length}
            <b>
              {/* @ts-ignore */}
              {
                // @ts-ignore
                t(
                  // @ts-ignore
                  `unit_of_measurement.${
                    choosenItem?.unit_of_measurement ?? ""
                  }`
                )
              }
            </b>
          </Typography>
          <Typography
            variant={"body2"}
            sx={{
              "& span": {
                color: "#DA281A",
                fontWeight: "bold",
              },
            }}
          >
            {/* @ts-ignore */}
            <span>{t("common.thickness")}:</span> {choosenItem?.thickness}
            {/* @ts-ignore */}
            <b>
              {/* @ts-ignore */}
              {t(
                // @ts-ignore
                `unit_of_measurement.${choosenItem?.unit_of_measurement ?? ""}`
              )}
            </b>
          </Typography>
        </Box>

        <Box>
          <Typography
            variant={"body2"}
            sx={{
              "& span": {
                color: "#DA281A",
                fontWeight: "bold",
              },
            }}
          >
            {/* @ts-ignore */}
            <span>{t("common.coating")}:</span>{" "}
            {getTranslate("name", choosenItem?.coating ?? {})}
          </Typography>
          <Typography
            variant={"body2"}
            sx={{
              "& span": {
                color: "#DA281A",
                fontWeight: "bold",
              },
            }}
          >
            {/* @ts-ignore */}
            <span>{t("common.weight")}:</span> {choosenItem?.weight}
          </Typography>
          <Typography
            variant={"body2"}
            sx={{
              "& span": {
                color: "#DA281A",
                fontWeight: "bold",
              },
            }}
          >
            {/* @ts-ignore */}
            <span>{t("common.angle")}:</span> {choosenItem?.angle}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default memo(Description);
