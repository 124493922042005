import {Box, Button, Dialog, DialogContent, DialogTitle, IconButton, TextField, Typography} from "@mui/material";
import CloseSvg from "../../../../assets/svgs/common/CloseSvg";
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import FileUpload from "../../FileUpload";
import useToast from "../../../../hooks/useToast";
import ButtonLoadingWrapper from "../../../Wrappers/ButtonLoading";
import {useGeneralStore} from "../../../../store/generalStore";
import useAsyncWrapper from "../../../../hooks/useAsyncWrapper";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import isEmail from "../../../../utils/isEmail";

interface ISettingsProps {
  open: boolean;
  handleClose: () => void;
}
const SetUpEmails = ({ handleClose, open} : ISettingsProps ) => {
  const { t } = useTranslation()

  const { errorToast, successToast } = useToast();
  const [value, setValue] = useState<string>('');
  const [emails, setEmails] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>('');

  const getSystem = useGeneralStore(state => state.getSystemSettings)
  const editEmails = useGeneralStore(state => state.editEmails)

  const editEmailsHandler = useAsyncWrapper(editEmails)
  const getSystemHandler = useAsyncWrapper(getSystem)


  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if(!isEmail(event.target.value) && event.target.value.length > 4) {
      // @ts-ignore
      setError(t('error.invalidEmail'))
    } else {
      setError('')
    }

    setValue(event.target.value);
  }

  const handleSave = async () => {
    setIsLoading(true)
    const {error} = await editEmailsHandler([{mail_for_sending_project: emails}])
    setIsLoading(false)
    if(!error) {
      // @ts-ignore
      successToast(t('common.emails_saved'))
      handleClose()
      return
    }
    // @ts-ignore
    errorToast(t('common.error'))

  }
  const handleAdd = async () => {
    setEmails([...emails, value])
    setValue('')
  }
  const handleDelete = async (value: string) => {
    setEmails(prev => prev.filter(el => el !== value))
  }

  useEffect(() => {
    (async () => {
      if(open) {
        const {data, error} = await getSystemHandler();
        if(!error) {
          setEmails(data?.data?.data?.mail_for_sending_project)
        }
      } else {
        setEmails([]);
        setValue('');
        setError('');
      }
    })();
  },[open])



  return <Dialog
    open={open}
    onClose={handleClose}
    sx={{
      '& .MuiBackdrop-root': {
        backgroundColor: 'rgba(0,0,0,0.1)' // Try to remove this to see the result
      },
      '& .MuiPaper-root': {
        width: '100%'
      }

    }}
  >
    <DialogTitle sx={{
      display: 'flex',
      justifyContent: 'space-between',
      fontWeight: 600,
      color:'#373737',
      borderBottom: '2px solid #373737',
      marginBottom: '20px',
    }}>
      {/* @ts-ignore */}
      {t('common.emailsSystem')}
      <Box onClick={handleClose}>
        <CloseSvg />
      </Box>
    </DialogTitle>


    <DialogContent sx={{
      display: 'flex',
      flexDirection: 'column',
      gap: '25px',
      marginTop: '5px'
    }}>
      <Box sx={{
        display: 'flex',
        flexDirection: 'column',
        padding: '5px 0'
      }}>

        <TextField
          fullWidth
          // @ts-ignore
          label={t('common.email')}
          variant="outlined"
          type={'email'}
          value={value}
          error={!!error}
          helperText={error}
          onChange={handleChange}
          InputProps={{
            endAdornment: <IconButton
              disabled={!value || isLoading || emails?.includes(value) || value.length < 5 || !!error}
              onClick={handleAdd}
              sx={{
                '&:disabled': {
                  opacity: 0.3
                }
              }}
            >
              <AddIcon color={'primary'}/>
            </IconButton>
          }}
        />
        <Box sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '10px',
          overflowY: 'auto',
          maxHeight: '60vh',
        }}>
          {emails?.map((el, i) => <Box sx={{
            display: 'flex',
            gap: '10px',
            justifyContent: 'space-between',
            alignItems: 'center',
            borderBottom: '1px solid #E0E0E0',
            padding: '5px 10px',
            backgroundColor: i % 2 ? '#F5F5F5' : 'transparent',
          }}>
            <Typography>
              {el}
            </Typography>
            <IconButton onClick={() => handleDelete(el)}>
              <DeleteIcon color={'error'}/>
            </IconButton>
          </Box>)}
          
        </Box>

      </Box>
      <ButtonLoadingWrapper isLoading={isLoading}>
        <Button
          variant={'contained'}
          color={'error'}
          onClick={handleSave}
          disabled={isLoading || !emails?.length}
        >
          {/* @ts-ignore */}
          {t('common.save')}
        </Button>
      </ButtonLoadingWrapper>
    </DialogContent>
  </Dialog>

}

export default SetUpEmails