import { Box, Button } from "@mui/material";
import { StyledHeaderWrapper } from "./style";
import { useTranslation } from "react-i18next";
import React, { useEffect, useState } from "react";
// import CreateLid from "../../../../Modals/CreateLid";
import AddBoxOutlinedIcon from "@mui/icons-material/AddBoxOutlined";
import Filter from "../Filter";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import usePermission from "../../../../../../hooks/usePermission";
import { useLidsStore } from "../../../../../../store/lidsStore";
import { PermissionsUser } from "../../../../../../interfaces/enum";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import UploadFile from "../../../../Modals/UploadFile";
import CreateLid from "../../../../Modals/CreateLid";
import { useFilesStore } from "../../../../../../store/filesStore";
import useAsyncWrapper from "../../../../../../hooks/useAsyncWrapper";
import useToast from "../../../../../../hooks/useToast";
import ButtonLoadingWrapper from "../../../../../Wrappers/ButtonLoading";

interface IProps {}

const LidsTableHeader = ({}: IProps) => {
  const { t } = useTranslation();
  const { errorToast, successToast } = useToast();
  const [createLidModalOpen, setCreateLidModalOpen] = useState(false);
  const [importModal, setImportModal] = useState(false);
  const [isExportLoading, setIsExportLoading] = useState(false);

  const { hasPermission } = usePermission();

  const filters = useLidsStore((state) => state.filter.filters);
  const resetFilter = useLidsStore((state) => state.resetFilter);
  const isFilters =
    filters?.length > 0 && filters?.some((el) => el.value !== "");

  const importFiles = useFilesStore((state) => state.importFile);
  const exportFile = useFilesStore((state) => state.exportFile);

  const importFileHandler = useAsyncWrapper(importFiles);
  const exportFileHandler = useAsyncWrapper(exportFile);

  const handleSubmit = async (file: any) => {
    const { error } = await importFileHandler(["lid", file]);
    if (!!error) {
      // @ts-ignore
      errorToast(t("files.import.error"));
      return;
    }
    // @ts-ignore
    successToast(t("files.import.successProcess"));
    handleCloseModal();
  };
  const handleExport = async () => {
    setIsExportLoading(true);
    const { error } = await exportFileHandler(["lid"]);
    setTimeout(() => {
      setIsExportLoading(false);
    }, 1000);

    if (!error) {
      // @ts-ignore
      successToast(t("files.export.successProcess"));
      return;
    }
    // @ts-ignore
    errorToast(t("files.export.error"));
  };
  const handleCloseModal = () => {
    setCreateLidModalOpen(false);
    setImportModal(false);
  };

  const handleOpenModal = () => {
    setCreateLidModalOpen(true);
  };

  return (
    <StyledHeaderWrapper>
      <Filter />
      <Box
        sx={{
          display: "flex",
          gap: "15px",
          width: "100%",
          flexWrap: "wrap",
        }}
      >
        <Button
          onClick={handleOpenModal}
          variant={"contained"}
          disabled={!hasPermission(PermissionsUser.lidCreate)}
          color={"error"}
          sx={{
            padding: "8px 10px",
            fontSize: "12px",
            fontWeight: "600",
            borderRadius: "8px",
            lineHeight: "1.5em",
          }}
          startIcon={<AddBoxOutlinedIcon />}
        >
          {/* @ts-ignore */}
          {t("createLid.title")}
        </Button>
        {isFilters && (
          <Button
            onClick={resetFilter}
            variant={"outlined"}
            color={"error"}
            sx={{
              padding: "8px 10px",
              fontSize: "12px",
              fontWeight: "600",
              borderRadius: "8px",
              lineHeight: "1.5em",
            }}
            startIcon={<RestartAltIcon />}
          >
            {/* @ts-ignore */}
            {t("common.reset")}
          </Button>
        )}
        <Box sx={{ flexGrow: 1 }} />
        <Button
          onClick={() => setImportModal(true)}
          variant={"contained"}
          disabled={!hasPermission(PermissionsUser.fileImport)}
          color={"success"}
          sx={{
            padding: "8px 10px",
            fontSize: "12px",
            fontWeight: "600",
            borderRadius: "8px",
            lineHeight: "1.5em",
            minWidth: "110px",
            "@media (max-width: 600px)": {
              minWidth: "auto",
            },
          }}
          startIcon={<FileUploadIcon />}
        >
          {/* @ts-ignore */}
          {t("common.import")}
        </Button>
        <Button
          onClick={handleExport}
          disabled={
            !hasPermission(PermissionsUser.fileExport) || isExportLoading
          }
          variant={"contained"}
          color={"warning"}
          sx={{
            padding: "8px 10px",
            fontSize: "12px",
            fontWeight: "600",
            borderRadius: "8px",
            lineHeight: "1.5em",
            minWidth: "110px",
            "@media (max-width: 600px)": {
              minWidth: "auto",
            },
          }}
          startIcon={<FileDownloadIcon />}
        >
          {/* @ts-ignore */}
          {t("common.export")}
        </Button>
      </Box>
      <CreateLid open={createLidModalOpen} handleClose={handleCloseModal} />
      <UploadFile
        open={importModal}
        handleClose={handleCloseModal}
        handleSubmit={handleSubmit}
      />
    </StyledHeaderWrapper>
  );
};

export default LidsTableHeader;
