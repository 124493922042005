import {Box, Dialog, DialogContent, Typography} from "@mui/material";
import {StyledDialogTitle} from "../../Auth/ForgotPassword/style";
import CloseSvg from "../../../../assets/svgs/common/CloseSvg";
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import CreateLidForm from "./CreateLidForm";
import {useImagesStore} from "../../../../store/imagesStore";

interface ISettingsProps {
  open: boolean;
  handleClose: () => void;
}
const CreateLid = ({ handleClose, open } : ISettingsProps ) => {
  const { t } = useTranslation()
  const setChoosedImages = useImagesStore(state => state.setChoosedImages)

  useEffect(() => {
    if(!open) {
      setChoosedImages([])
    }
  },[open])

  return <Dialog
    open={open}
    onClose={handleClose}
  >
    <StyledDialogTitle>
      <Typography
        fontWeight={600}
        variant={'h6'}
        color={'#373737'}
      >
        {/* @ts-ignore */}
        { t('createLid.title')}
      </Typography>
      <Box onClick={handleClose}>
        <CloseSvg />
      </Box>
    </StyledDialogTitle>


    <DialogContent>
      <CreateLidForm handleClose={handleClose} />
    </DialogContent>
  </Dialog>

}

export default CreateLid