import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  InputAdornment,
  TextField,
  useMediaQuery,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useProjectsStore } from "../../../../../store/projectsStore";
import { useDebounce } from "../../../../../hooks/useDebounce";
import { useUsersTableStore } from "../../../../../store/usersTableStore";
import useAsyncWrapper from "../../../../../hooks/useAsyncWrapper";
import AsyncSelect from "../../../AsyncSelect/AsyncSelect";
import { useUserStore } from "../../../../../store/userStore";
import { users_api } from "../../../../../api/apis/user.api";
import usePermissions from "../../../../../hooks/usePermission";

const Filter = () => {
  const { t } = useTranslation();
  const media = useMediaQuery("(max-width: 550px)");
  const [search, setSearch] = useState("");
  const { isUser } = usePermissions();
  const debouncedSearchTerm = useDebounce<string>(search, 500);

  const filter = useProjectsStore((state) => state.filter);
  const setFilter = useProjectsStore((state) => state.setFilter);
  const resetFilter = useProjectsStore((state) => state.resetFilter);

  const handleChangeSearch = (e: any) => {
    setSearch(e.target.value);
  };

  const dataSetterInsurance = async (page: number, search: string) => {
    try {
      const res = await users_api.users({
        page,
        perpage: 10,
        search: search,
        filters: [],
      });
      return { data: res?.data?.data, pages: res?.data?.meta.last_page };
    } catch (err) {
      return { data: null, pages: 0 };
    }
  };
  const handleChangeFilter = (dataArray: any) => {
    setFilter({
      ...filter,
      filters: [
        {
          field_name: "user_id",
          rule: "equal",
          value: dataArray?.id ?? "",
        },
      ],
    });
  };

  useEffect(() => {
    return () => {
      resetFilter();
    };
  }, []);

  useEffect(() => {
    setFilter({
      ...filter,
      search: debouncedSearchTerm,
    });
  }, [debouncedSearchTerm]);

  return (
    <Box
      sx={{
        display: "flex",
        gap: "10px",
        flexWrap: "wrap",
        alignItems: "flex-end",
        paddingLeft: "15px",
        marginBottom: "15px",
      }}
    >
      <TextField
      // @ts-ignore
        label={t("common.search")}
        type="search"
        variant="standard"
        onChange={handleChangeSearch}
        sx={{
          minWidth: "250px",
        }}
        value={search ?? ""}
        InputProps={{
          endAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
      />
      {!isUser && (
        <AsyncSelect
        // @ts-ignore
          placeholder={t("common.users")}
          limitTags={1}
          variant={"standard"}
          defaultValues={null}
          DataSetter={dataSetterInsurance}
          selectHandler={handleChangeFilter}
        />
      )}
    </Box>
  );
};

export default Filter;
