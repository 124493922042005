import {
  Box,
  Button,
  InputAdornment,
  TextField,
  useMediaQuery,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useCoatingsStore } from "../../../../../../store/coatingsStore";

const Filter = () => {
  const { t } = useTranslation();
  const media = useMediaQuery("(max-width: 550px)");
  const [isShort, setIsShort] = useState(!media);

  const filter = useCoatingsStore((state) => state.filter);
  const setFilter = useCoatingsStore((state) => state.setFilter);
  const resetFilter = useCoatingsStore((state) => state.resetFilter);

  const handleSearch = (e: any, field: string) => {
    setFilter({
      ...filter,
      filters: filter.filters?.find((el) => el.field_name === field)
        ? filter.filters?.map((el) =>
            el.field_name === field ? { ...el, value: e.target.value } : el
          )
        : [
            ...filter.filters,
            {
              field_name: field,
              rule: "contains",
              value: e.target.value,
            },
          ],
    });
  };

  const getValue = (field: string) => {
    return filter?.filters?.find((el) => el?.field_name === field)?.value ?? "";
  };

  useEffect(() => {
    return () => {
      resetFilter();
    };
  }, []);
  useEffect(() => {
    setIsShort(media);
  }, [media]);
  return (
    <>
      <Box
        sx={{
          display: "flex",
          gap: "10px",
          flexWrap: "wrap",
          alignItems: "flex-end",
        }}
      >
        <TextField
        // @ts-ignore
          label={t("common.searchBy.title") + " " + t("common.searchBy.id")}
          type="search"
          variant="standard"
          onChange={(e) => handleSearch(e, "id")}
          sx={{
            minWidth: "250px",
          }}
          value={getValue("id")}
          InputProps={{
            endAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
        <TextField
        // @ts-ignore
          label={t("common.searchBy.title") + " " + t("common.searchBy.code")}
          type="search"
          variant="standard"
          onChange={(e) => handleSearch(e, "code")}
          sx={{
            minWidth: "250px",
          }}
          value={getValue("code")}
          InputProps={{
            endAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
        <br />
      </Box>
      {media && (
        <Button
          onClick={() => setIsShort((prev) => !prev)}
          variant={"outlined"}
          endIcon={
            <ExpandMoreIcon
              sx={{ transform: !isShort ? "rotate(180deg)" : "rotate(0deg)" }}
            />
          }
          color={"warning"}
          sx={{
            transition: "all 0.3s ease",
            "& svg": {
              transition: "all 0.3s ease",
            },
          }}
        >
          {/* @ts-ignore */}
          {isShort ? t("common.showMore") : t("common.showLess")}
        </Button>
      )}
    </>
  );
};
export default Filter;
