import { Box, Button } from "@mui/material";
import { StyledHeaderWrapper } from "./style";
import { useTranslation } from "react-i18next";
import React, { useState } from "react";
// import CreateMetalProduct from "../../../../Modals/CreateMetalProduct";
import AddBoxOutlinedIcon from "@mui/icons-material/AddBoxOutlined";
import Filter from "../Filter";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import usePermission from "../../../../../../hooks/usePermission";
import { PermissionsUser } from "../../../../../../interfaces/enum";
import UploadFile from "../../../../Modals/UploadFile";
import { useCoatingsStore } from "../../../../../../store/coatingsStore";
import CreateCoating from "../../../../Modals/CreateCoating";

interface IProps {}

const CoatingsTableHeader = ({}: IProps) => {
  const { t } = useTranslation();
  const [createCoatingModalOpen, setCreateCoatingModalOpen] = useState(false);
  const [importModal, setImportModal] = useState(false);
  const { hasPermission } = usePermission();

  const filters = useCoatingsStore((state) => state.filter.filters);
  const resetFilter = useCoatingsStore((state) => state.resetFilter);
  const isFilters =
    filters?.length > 0 && filters?.some((el) => el.value !== "");

  const handleCloseModal = () => {
    setCreateCoatingModalOpen(false);
    setImportModal(false);
  };

  const handleOpenModal = () => {
    setCreateCoatingModalOpen(true);
  };

  return (
    <StyledHeaderWrapper>
      <Filter />
      <Box
        sx={{
          display: "flex",
          gap: "15px",
          width: "100%",
          flexWrap: "wrap",
        }}
      >
        <Button
          onClick={handleOpenModal}
          variant={"contained"}
          disabled={!hasPermission(PermissionsUser.coatingCreate)}
          color={"error"}
          sx={{
            padding: "8px 10px",
            fontSize: "12px",
            fontWeight: "600",
            borderRadius: "8px",
            lineHeight: "1.5em",
          }}
          startIcon={<AddBoxOutlinedIcon />}
        >
          {/* @ts-ignore */}
          {t("createCoating.title")}
        </Button>
        {isFilters && (
          <Button
            onClick={resetFilter}
            variant={"outlined"}
            color={"error"}
            sx={{
              padding: "8px 10px",
              fontSize: "12px",
              fontWeight: "600",
              borderRadius: "8px",
              lineHeight: "1.5em",
            }}
            startIcon={<RestartAltIcon />}
          >
            {/* @ts-ignore */}
            {t("common.reset")}
          </Button>
        )}
      </Box>
      <CreateCoating
        open={createCoatingModalOpen}
        handleClose={handleCloseModal}
      />
      <UploadFile
        open={importModal}
        handleClose={handleCloseModal}
        handleSubmit={() => {}}
      />
    </StyledHeaderWrapper>
  );
};

export default CoatingsTableHeader;
