import {TableHead, TableRow} from "@mui/material";
import {useTranslation} from "react-i18next";
import {StyledTableCell} from "./style";

const MetalProductsTableHead = () => {
  const { t } = useTranslation()

  return <TableHead sx={{
    borderBottom: '3px double #ccc'
  }}>
    <TableRow>
      <StyledTableCell>
        {/* @ts-ignore */}
        {t('metalProducts.id')}
      </StyledTableCell>
      <StyledTableCell>
        {/* @ts-ignore */}
        {t('metalProducts.name_en')}
      </StyledTableCell>
      <StyledTableCell>
        {/* @ts-ignore */}
        {t('metalProducts.name_uk')}
      </StyledTableCell>
      <StyledTableCell>
        {/* @ts-ignore */}
        {t('metalProducts.article_prefix_1')}
      </StyledTableCell>
      <StyledTableCell>
        {/* @ts-ignore */}
        {t('metalProducts.article_prefix_2')}
      </StyledTableCell>
      <StyledTableCell>
        {/* Add any additional columns if needed */}
      </StyledTableCell>
    </TableRow>
  </TableHead>
}

export default MetalProductsTableHead