import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import CloseSvg from "../../../../assets/svgs/common/CloseSvg";
import React, { memo, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useProjectStore } from "../../../../store/projectStore";
import useLang from "../../../../hooks/useLang";
import useAsyncWrapper from "../../../../hooks/useAsyncWrapper";
import useToast from "../../../../hooks/useToast";
import { Check } from "@mui/icons-material";

interface ISettingsProps {
  open: boolean;
  handleClose: () => void;
}
const ChooseTypePartition = ({ handleClose, open }: ISettingsProps) => {
  const { t } = useTranslation();
  const { getTranslate } = useLang();
  const { errorToast } = useToast();

  const [loading, setLoading] = useState(false);
  const [tempPartition, setTempPartition] = useState<any>(null);

  const setPartition = useProjectStore((state) => state.setPartition);
  const choosenItem = useProjectStore((state) => state.choosenItem);
  const currentPosition = useProjectStore((state) => state.currentPosition);
  const partitions = choosenItem?.tray?.partitions;

  const setChoosenPartition = useProjectStore(
    (state) => state.setChoosenPartition
  );
  const getPartitionsByTray = useProjectStore(
    (state) => state.getPartitionsByTray
  );

  const getPartitionsByTrayHandler = useAsyncWrapper(getPartitionsByTray);

  const cancel = () => {
    handleClose();
    if (!currentPosition?.lid_model_parameter?.lid_id) setPartition(false);
  };

  const choosePartition = (lid: any) => {
    setTempPartition(lid);
  };

  const handleChoose = async () => {
    if (!tempPartition) return;

    const payload = {
      tray_model_parameter_id: choosenItem?.id,
      partition_id: tempPartition?.id,
    };
    setLoading(true);
    const { error, data } = await getPartitionsByTrayHandler([payload]);
    setLoading(false);

    if (!!error || !data.data) {
      // @ts-ignore
      errorToast(t("project.noPartitionFoundForTray"));
      setPartition(false);
      handleClose();
      return;
    }

    setChoosenPartition(data.data);
    handleClose();
  };

  useEffect(() => {
    if (open) {
      if (currentPosition?.lid_model_parameter) {
        const partitionId = currentPosition?.lid_model_parameter?.lid_id;
        setTempPartition(partitionId ? { id: partitionId } : null);
      } else {
        const defaultPartition = partitions?.find((item: any) =>
          item?.article_prefix_1?.includes("SPRT")
        );
        setTempPartition(defaultPartition ?? null);
      }
    }
  }, [open, currentPosition]);

  return (
    <Dialog
      open={open}
      onClose={cancel}
      sx={{
        "& .MuiBackdrop-root": {
          backgroundColor: "rgba(0,0,0,0.1)", // Try to remove this to see the result
        },
        "& .MuiDialog-paper": {
          boxShadow: "none",
          maxWidth: "900px",
        },
      }}
    >
      <DialogTitle
        sx={{
          display: "flex",
          justifyContent: "space-between",
          fontWeight: 600,
          color: "#373737",
        }}
      >
        {/* @ts-ignore */}
        {t("project.chooseTypePartition")}

        <Box onClick={cancel}>
          <CloseSvg />
        </Box>
      </DialogTitle>

      <DialogContent
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "25px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            gap: "15px",
            "@media (max-width: 900px)": {
              flexWrap: "wrap",
            },
          }}
        >
          {partitions?.length
            ? partitions?.map((item: any, i: number) => (
                <Button
                  key={item?.id}
                  disabled={loading || tempPartition?.id === item?.id}
                  variant={i % 2 ? "outlined" : "contained"}
                  color={i % 2 ? "secondary" : "error"}
                  onClick={() => choosePartition(item)}
                  endIcon={tempPartition?.id === item?.id ? <Check /> : null}
                  fullWidth
                >
                  {getTranslate("name", item)}
                </Button>
              ))
            : "No partitions"}
        </Box>

        <Box
          sx={{
            display: "flex",
            gap: "10px",
          }}
        >
          <Button
            fullWidth
            onClick={cancel}
            color={"warning"}
            variant={"outlined"}
          >
            {/* @ts-ignore */}
            {t("common.cancel")}
          </Button>
          <Button
            fullWidth
            onClick={handleChoose}
            // color={'primary'}
            disabled={!tempPartition || loading}
            variant={"contained"}
            sx={{
              backgroundColor: "#666",
              "&:hover": {
                backgroundColor: "#ccc",
              },
            }}
          >
            {/* @ts-ignore */}
            {t("common.save")}
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default memo(ChooseTypePartition);
