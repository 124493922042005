import { StyledWrapper } from "./style";
import { IImage } from "../../../../../../interfaces/general";
// import {env, imagesType} from "../../../../../../constants";
import { Box, Button, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
// import EditImage from "../../../../Modals/EditImage";
import { useState } from "react";
import ConfirmDelete from "../../../../Modals/ConfirmDelete";
import { useImagesStore } from "../../../../../../store/imagesStore";
import useAsyncWrapper from "../../../../../../hooks/useAsyncWrapper";
import useToast from "../../../../../../hooks/useToast";
import usePermissions from "../../../../../../hooks/usePermission";
import { PermissionsUser } from "../../../../../../interfaces/enum";
import { env } from "../../../../../../constants";

interface IProps {
  image: IImage;
}

const getTypeColor = (type: number) => {
  if (type === 1) return "#3876BF";
  if (type === 2) return "#DA281A";
  if (type === 3) return "#EE9322";
  return "#fff";
};

const ImageCard = ({ image }: IProps) => {
  const { t } = useTranslation();
  const { successToast, errorToast } = useToast();
  const { hasPermission } = usePermissions();
  const [open, setOpen] = useState({ del: false, edit: false });

  const deleteImage = useImagesStore((state) => state.deleteImage);
  const deleteImageHandler = useAsyncWrapper(deleteImage);

  const handleDeleteImage = async (id: number) => {
    const { error } = await deleteImageHandler([id]);

    if (!error) {
      // @ts-ignore
      successToast(t("deleteImage.success"));
      return;
    }
    // @ts-ignore
    errorToast(t("deleteImage.error"));
  };

  const handleClose = () => {
    setOpen({ del: false, edit: false });
  };

  const handleOpenEdit = () => {
    setOpen({ del: false, edit: true });
  };

  const handleOpenDelete = () => {
    setOpen({ del: true, edit: false });
  };

  const handleOpenFile = () => {
    window.open(
      env.REACT_APP_DEV_BASE_API_URL + "/" + image?.file_url,
      "_blank"
    );
  };

  return (
    <StyledWrapper sx={{}}>
      <Box
        onClick={handleOpenFile}
        component={"img"}
        sx={{
          position: "absolute",
          top: "0px",
          cursor: "pointer",
          left: "50%",
          transform: "translate(-50%, -50%)",
          border: "5px solid #fff",
          height: "90px",
          width: "90px",
          boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
          borderRadius: "50%",
          "@media (max-width: 1350px)": {
            height: "70px",
            width: "70px",
          },
          "@media (max-width: 1200px)": {
            height: "60px",
            width: "60px",
          },
        }}
        src={"./cam-icon.jpg"}
        alt={"ss"}
      />
      <Box
        component={"img"}
        src={env.REACT_APP_DEV_BASE_API_URL + "/" + image?.file_url}
        sx={{
          height: "160px",
          width: "160px",
          margin: "0 auto",
          // width: '100%',
          aspectRatio: "1/1",
          "@media (max-width: 1350px)": {
            maxHeight: "150px",
          },
          "@media (max-width: 1200px)": {
            maxHeight: "110px",
          },
        }}
      />
      <Box
        sx={{
          display: "flex",
          gap: "10px",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <Typography>
          {/* @ts-ignore */}
          {t("images.name")}: {image.file_name}
        </Typography>

        <Typography>{image.description_uk}</Typography>
      </Box>
      {
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-evenly",
            gap: "15px",
          }}
        >
          <Button
            variant={"outlined"}
            color={"warning"}
            disabled={!hasPermission(PermissionsUser.imageList)}
            fullWidth
            onClick={handleOpenEdit}
            sx={{
              fontWeight: "600",
              "@media (max-width: 1200px)": {
                fontSize: "12px",
              },
              "@media (max-width: 900px)": {
                fontSize: "10px",
              },
            }}
            startIcon={<EditIcon color={"warning"} />}
          >
            {/* @ts-ignore */}
            {t("common.edit")}
          </Button>
          <Button
            variant={"outlined"}
            fullWidth
            disabled={!hasPermission(PermissionsUser.imageDestroy)}
            sx={{
              fontWeight: "600",
              "@media (max-width: 1200px)": {
                fontSize: "12px",
              },
              "@media (max-width: 900px)": {
                fontSize: "10px",
              },
            }}
            color={"error"}
            onClick={handleOpenDelete}
            startIcon={<DeleteIcon color={"error"} />}
          >
            {/* @ts-ignore */}
            {t("common.delete")}
          </Button>
        </Box>
      }
      <ConfirmDelete
      // @ts-ignore
        text={t("deleteImage.submissionText")}
        // @ts-ignore
        title={t("deleteImage.title")}
        handleSubmit={() => handleDeleteImage(image.id)}
        handleClose={handleClose}
        open={open.del}
      />
      {/*<EditImage id={image.id} open={open.edit} handleClose={handleClose}/>*/}
    </StyledWrapper>
  );
};

export default ImageCard;
