import {Box, Button, TableBody, TableRow, Typography} from "@mui/material";
import {useTranslation} from "react-i18next";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import {StyledActionButton, StyledTableCell} from "./style";
import {formatDate} from "../../../../../../utils/formatDate";
import {useUsersTableStore} from "../../../../../../store/usersTableStore";
import useAsyncWrapper from "../../../../../../hooks/useAsyncWrapper";
import ConfirmDelete from "../../../../Modals/ConfirmDelete";
import {useState} from "react";
import EditUser from "../../../../Modals/EditUser";
import NoTableData from "../../../../NoTableData";
import usePermission from "../../../../../../hooks/usePermission";
import {PermissionsUser} from "../../../../../../interfaces/enum";
import useToast from "../../../../../../hooks/useToast";
import EditMetalProduct from "../../../../Modals/EditMetalProduct";
import {useMetalProductStore} from "../../../../../../store/metalProductsStore";
import {arrayJoin} from "../../../../../../utils/arrayJoin";


interface IProps {
  data: any[]
}
const MetalProductTableBody = ({ data }: IProps) => {
  const { t, i18n } = useTranslation()
  const { errorToast, successToast} = useToast()
  const [id, setId] = useState(0);
  const { hasPermission } = usePermission()

  const currentLangEn = i18n.language === 'en';

  const [open, setOpen] = useState({
    delete: false,
    edit: false
  });

  const handleOpenDelete = (id: number) => {
    setId(id)

    setOpen({
      ...open,
      delete: true
    })
  }
  const handleOpenEdit = (id: number) => {
    setId(id)
    setOpen({
      ...open,
      edit: true
    })
  }
  const handleCloseDelete = () => {
    setOpen({
      ...open,
      delete: false
    })
    setId(0)
  }
  const handleCloseEdit = () => {
    setOpen({
      ...open,
      edit: false
    })
    setId(0)
  }

  const deleteMetalProduct = useMetalProductStore(state => state.deleteMetalProduct);
  const deleteMetalProductHandler = useAsyncWrapper(deleteMetalProduct);

  const handleDeleteMetalProduct = async (id: number) => {
    const {error} = await deleteMetalProductHandler([id])
    if(!error) {
      // @ts-ignore
      successToast(t('deleteMetalProduct.success'))
      return;
    }
    // @ts-ignore
    errorToast(t('deleteMetalProduct.error'));  }


  return (<TableBody>

    {data
      ?.map((row) => {
        return (
          <TableRow
            hover
            role="checkbox"
            tabIndex={-1}
            key={row.id}
            sx={{
              '&.MuiTableRow-hover:hover': {
                opacity: 0.8,
              },
              '& .MuiTableCell-root': {
                backgroundColor: row.blocked ? '#d32f2f' : '#D8D9DA',
                color: row.blocked ? 'white' : 'black'
              }
            }}>
            <StyledTableCell>
              <Typography>{row.id}</Typography>
            </StyledTableCell>
            <StyledTableCell>
              <Typography>{row.name_en}</Typography>
            </StyledTableCell>
            <StyledTableCell>
              <Typography>{row.name_uk}</Typography>
            </StyledTableCell>
            <StyledTableCell>
              <Typography>{row.article_prefix_1}</Typography>
            </StyledTableCell>
            <StyledTableCell>
              <Typography>{row.article_prefix_2}</Typography>
            </StyledTableCell>
            <StyledTableCell>
              <Box sx={{
                display: 'flex',
                gap: '5px'
              }}>
                <StyledActionButton disabled={!hasPermission(PermissionsUser.metalProductUpdate)} onClick={() => handleOpenEdit(row.id)}>
                  <EditIcon color={'warning'} />
                </StyledActionButton>
                <StyledActionButton disabled={!hasPermission(PermissionsUser.metalProductDestroy)} onClick={() => handleOpenDelete(row.id)}>
                  <DeleteIcon color={'error'}/>
                </StyledActionButton>
              </Box>
            </StyledTableCell>
          </TableRow>
        );
      })}
    <ConfirmDelete
    // @ts-ignore
      text={t('deleteMetalProduct.submissionText')}
      // @ts-ignore
      title={t('deleteMetalProduct.title')}
      handleSubmit={() => handleDeleteMetalProduct(id)}
      handleClose={handleCloseDelete}
      open={open.delete}
    />
    <EditMetalProduct
      id={id}
      open={open.edit}
      handleClose={handleCloseEdit}
    />
  </TableBody>)
}

export default MetalProductTableBody