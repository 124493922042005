import { Box, Typography, useMediaQuery } from "@mui/material";
import { useTranslation } from "react-i18next";
import { StyledHeader } from "./style";
import UserProfile from "./components/UserProfile";
import SelectLang from "./components/SelectLang";
import { useLocation, useParams } from "react-router-dom";
import React, { memo, useState } from "react";
import { RouteUrls } from "../../../interfaces/enum";
import ProjectNav from "./components/ProjectNav";

const Header = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const location = useLocation().pathname;

  const isProject = location.includes(RouteUrls.PROJECTS) && id;

  const currentPage = useLocation()?.pathname?.split("/")[1];

  return (
    <StyledHeader>
      {!isProject ? (
        <Typography
          variant={"h3"}
          color={"#DA281A"}
          fontSize={"40px"}
          fontWeight={300}
        >
          {/* @ts-ignore */}
          {t(`header.${currentPage}`)}
        </Typography>
      ) : (
        <ProjectNav />
      )}
      <Box
        sx={{
          display: "flex",
          gap: "20px",
          alignItems: "center",
        }}
      >
        <SelectLang />
        <UserProfile />
      </Box>
    </StyledHeader>
  );
};
export default memo(Header);
