import React, { useState } from "react";
import { Box, Divider, IconButton, Tooltip } from "@mui/material";
import { useTranslation } from "react-i18next";
import useLang from "../../../../../../../hooks/useLang";
import { IPositionItem } from "../../../../../../../interfaces/general";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ClearIcon from "@mui/icons-material/Clear";
import { useProjectStore } from "../../../../../../../store/projectStore";
import useAsyncWrapper from "../../../../../../../hooks/useAsyncWrapper";
import useToast from "../../../../../../../hooks/useToast";

interface IProps {
  tray: IPositionItem;
  index: number;
}

const CartItem = ({ tray, index }: IProps) => {
  const { t } = useTranslation();
  const { getTranslate } = useLang();
  const { errorToast, successToast } = useToast();

  const [showMore, setShowMore] = useState(false);

  const setChoosenItem = useProjectStore((state) => state.setChoosenItem);
  const setProjectLength = useProjectStore((state) => state.setProjectLength);
  const setLid = useProjectStore((state) => state.setLid);
  const setPartition = useProjectStore((state) => state.setPartition);
  const setTrayQuantity = useProjectStore((state) => state.setTrayQuantity);
  const setCurrentCoating = useProjectStore((state) => state.setCurrentCoating);
  const setCurrentPosition = useProjectStore(
    (state) => state.setCurrentPosition
  );
  const setAccessoryView = useProjectStore((state) => state.setAccessoryView);
  const currentPosition = useProjectStore((state) => state.currentPosition);

  const deletePosition = useProjectStore((state) => state.deletePosition);
  const editPosition = useProjectStore((state) => state.editPosition);

  const deletePositionHandler = useAsyncWrapper(deletePosition);
  const editPositionHandler = useAsyncWrapper(editPosition);

  const isItemChoosed = currentPosition?.id === tray?.id;

  const showMoreHandler = () => {
    setShowMore((prev) => !prev);
  };
  const handleDeletePosition = async () => {
    const { error } = await deletePositionHandler([tray?.id]);
    if (!error) {
      // @ts-ignore
      successToast(t("project.successDelete"));
      return;
    }
    // @ts-ignore
    errorToast(t("project.errorDelete"));
  };
  const handleDeleteAccessory = async (accessory: any) => {
    const accessories =
      tray?.accessories?.filter(
        (el: any) =>
          el.count === accessory?.count &&
          el.model_parameter?.id !== accessory?.model_parameter?.id
      ) ?? [];

    const payload = {
      accessories,
    };

    const { error } = await editPositionHandler([payload, tray?.id]);
    if (!error) {
      // @ts-ignore
      successToast(t("project.successDelete"));
      return;
    }
    // @ts-ignore
    errorToast(t("project.errorDelete"));
  };

  const handleOpen = () => {
    if (currentPosition?.id === tray?.id) {
      setCurrentPosition(null);
      return;
    }
    setChoosenItem(tray?.tray_model_parameter);
    setProjectLength(tray?.length);
    setLid(tray?.with_lid);
    setTrayQuantity(tray?.quantity);
    setCurrentCoating(tray?.tray_model_parameter?.coating);
    setAccessoryView(false);
    setCurrentPosition(tray);
    setPartition(tray?.with_partition);
  };

  return (
    <Box
      key={tray?.id}
      sx={{
        display: "flex",
        flexDirection: "column",
        fontSize: "13px",
        backgroundColor: isItemChoosed
          ? "khaki"
          : index % 2
          ? "#F5F5F5"
          : "transparent",
        borderTop: "1px solid rgba(0, 0, 0, 0.12)",
        borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
      }}
    >
      <Box
        sx={{
          flexGrow: 1,
          display: "flex",
          gap: "10px",
          alignItems: "center",
          padding: "0 10px",
        }}
      >
        <Box
          sx={{
            width: "60%",
            color: "#DA281A",
            fontWeight: 600,
            "& span": {
              cursor: "pointer",
            },
          }}
        >
          <span onClick={handleOpen}>
            {getTranslate("description", tray?.tray_model_parameter)}
          </span>
          {(tray?.mandatory_products?.length || tray?.accessories?.length) && (
            <IconButton
              onClick={showMoreHandler}
              sx={{
                padding: 0,
                transform: `rotate(${showMore ? 180 : 0}deg)`,
              }}
            >
              <ExpandMoreIcon />
            </IconButton>
          )}
        </Box>
        <Box
          sx={{
            width: "20%",
          }}
        >
          {tray?.tray_model_parameter?.article}
        </Box>
        <Box
          sx={{
            width: "10%",
            textAlign: "center",
          }}
        >
          {tray?.quantity}
        </Box>
        <Box
          sx={{
            width: "10%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {t(
            // @ts-ignore
            `unit_of_measurement.${tray?.tray_model_parameter?.unit_of_measurement}`
          )}
          {(tray?.mandatory_products?.length || tray?.accessories?.length) && (
            <IconButton
              onClick={handleDeletePosition}
              sx={{
                padding: 0,
              }}
            >
              <ClearIcon color={"error"} />
            </IconButton>
          )}
        </Box>
      </Box>

      {showMore && (
        <>
        {/* @ts-ignore */}
          <Tooltip title={t("project.formulaRequired")}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "5px",
              }}
            >
              {/******* mandatory_lid  ********/}

              {tray.with_lid && tray?.lid_model_parameter && (
                <Box
                  sx={{
                    opacity: 0.6,
                    flexGrow: 1,
                    display: "flex",
                    gap: "15px",
                    alignItems: "center",
                    padding: "0 10px",
                    fontSize: "11px",
                  }}
                >
                  <Box
                    sx={{
                      width: "60%",
                      paddingLeft: "20px",
                    }}
                  >
                    {getTranslate("description", tray?.lid_model_parameter)}
                  </Box>
                  <Box
                    sx={{
                      width: "20%",
                    }}
                  >
                    {tray?.lid_model_parameter?.article}
                  </Box>
                  <Box
                    sx={{
                      width: "10%",
                      textAlign: "center",
                    }}
                  >
                    {tray?.quantity}
                  </Box>
                  <Box
                    sx={{
                      width: "10%",
                      textAlign: "center",
                    }}
                  >
                    {t(
                      // @ts-ignore
                      `common.${tray?.lid_model_parameter?.unit_of_measurement}`
                    )}
                  </Box>
                </Box>
              )}

              {/******* mandatory_partition  ********/}

              {tray.with_partition && tray?.partition_model_parameter && (
                <Box
                  sx={{
                    opacity: 0.6,
                    flexGrow: 1,
                    display: "flex",
                    gap: "15px",
                    alignItems: "center",
                    padding: "0 10px",
                    fontSize: "11px",
                  }}
                >
                  <Box
                    sx={{
                      width: "60%",
                      paddingLeft: "20px",
                    }}
                  >
                    {getTranslate(
                      "description",
                      tray?.partition_model_parameter
                    )}
                  </Box>
                  <Box
                    sx={{
                      width: "20%",
                    }}
                  >
                    {tray?.partition_model_parameter?.article}
                  </Box>
                  <Box
                    sx={{
                      width: "10%",
                      textAlign: "center",
                    }}
                  >
                    {tray?.quantity}
                  </Box>
                  <Box
                    sx={{
                      width: "10%",
                      textAlign: "center",
                    }}
                  >
                    {t(
                      // @ts-ignore
                      `common.${tray?.partition_model_parameter?.unit_of_measurement}`
                    )}
                  </Box>
                </Box>
              )}

              {/******* mandatory_products  ********/}

              {tray?.mandatory_products?.concat(tray?.screws ?? []).map(
                (accessory) =>
                  accessory?.model && (
                    <Box
                      sx={{
                        opacity: 0.6,
                        flexGrow: 1,
                        display: "flex",
                        gap: "15px",
                        alignItems: "center",
                        padding: "0 10px",
                        fontSize: "11px",
                      }}
                    >
                      <Box
                        sx={{
                          width: "60%",
                          paddingLeft: "20px",
                        }}
                      >
                        {getTranslate("description", accessory?.model)}
                      </Box>
                      <Box
                        sx={{
                          width: "20%",
                        }}
                      >
                        {accessory?.modaccessory?.article}
                      </Box>
                      <Box
                        sx={{
                          width: "10%",
                          textAlign: "center",
                        }}
                      >
                        {accessory?.count}
                      </Box>
                      <Box
                        sx={{
                          width: "10%",
                          textAlign: "center",
                        }}
                      >
                        {/* @ts-ignore */}
                        {t(`common.${accessory?.model?.unit_of_measurement}`)}
                      </Box>
                    </Box>
                  )
              )}
            </Box>
          </Tooltip>
          {/******* accessories  ********/}
          {!!tray?.accessories?.length && <Divider />}
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "5px",
            }}
          >
            {tray?.accessories?.map(
              (accessory: any) =>
                accessory?.model_parameter && (
                  <Box key={accessory?.id}>
                    <Box
                      sx={{
                        color: "#666",
                        flexGrow: 1,
                        display: "flex",
                        gap: "15px",
                        alignItems: "center",
                        padding: "0 10px",
                        fontSize: "11px",
                      }}
                    >
                      <Box
                        sx={{
                          width: "60%",
                          paddingLeft: "20px",
                        }}
                      >
                        {getTranslate(
                          "description",
                          accessory?.model_parameter
                        )}
                      </Box>
                      <Box
                        sx={{
                          width: "20%",
                        }}
                      >
                        {accessory?.model_parameter?.article}
                      </Box>
                      <Box
                        sx={{
                          width: "10%",
                          textAlign: "center",
                        }}
                      >
                        {accessory?.count}
                      </Box>
                      <Box
                        sx={{
                          width: "10%",
                          textAlign: "center",
                        }}
                      >
                        {t(
                          // @ts-ignore
                          `common.${accessory?.model_parameter?.unit_of_measurement}`
                        )}
                        <IconButton
                          onClick={() => handleDeleteAccessory(accessory)}
                          sx={{
                            padding: 0,
                          }}
                        >
                          <ClearIcon
                            sx={{
                              fontSize: "18px",
                            }}
                            color={"error"}
                          />
                        </IconButton>
                      </Box>
                    </Box>
                    <Box>
                      {accessory?.lid_model_parameter && tray?.with_lid && (
                        <Box
                          sx={{
                            color: "#666",
                            flexGrow: 1,
                            display: "flex",
                            opacity: "0.6",
                            gap: "30px",
                            alignItems: "center",
                            padding: "0 0 0 20px",
                            fontSize: "11px",
                          }}
                        >
                          <Box
                            sx={{
                              width: "60%",
                              paddingLeft: "20px",
                            }}
                          >
                            {getTranslate(
                              "description",
                              accessory?.lid_model_parameter
                            )}
                          </Box>
                          <Box
                            sx={{
                              width: "20%",
                            }}
                          >
                            {accessory?.lid_model_parameter?.article}
                          </Box>
                          <Box
                            sx={{
                              width: "10%",
                              textAlign: "center",
                            }}
                          >
                            {accessory?.count}
                          </Box>
                          <Box
                            sx={{
                              width: "10%",
                              textAlign: "center",
                            }}
                          >
                            {t(
                              // @ts-ignore
                              `common.${accessory?.lid_model_parameter?.unit_of_measurement}`
                            )}
                          </Box>
                        </Box>
                      )}
                    </Box>
                  </Box>
                )
            )}
          </Box>
        </>
      )}
    </Box>
  );
};

export default CartItem;
