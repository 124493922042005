import {
  Autocomplete,
  Box,
  Button,
  FormControl,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  useMediaQuery,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useRecommendationsStore } from "../../../../../../store/recommendationsStore";
import { useCoatingsStore } from "../../../../../../store/coatingsStore";
import { installations, products } from "../../../../../../constants";

const Filter = () => {
  const { t } = useTranslation();
  const media = useMediaQuery("(max-width: 550px)");
  const [isShort, setIsShort] = useState(!media);

  const filter = useRecommendationsStore((state) => state.filter);
  const setFilter = useRecommendationsStore((state) => state.setFilter);
  const resetFilter = useRecommendationsStore((state) => state.resetFilter);
  const coatings = useCoatingsStore((state) => state.tableData.data);

  const handleSearch = (e: any, field: string) => {
    setFilter({
      ...filter,
      filters: filter.filters?.find((el) => el.field_name === field)
        ? filter.filters?.map((el) =>
            el.field_name === field ? { ...el, value: e.target.value } : el
          )
        : [
            ...filter.filters,
            {
              field_name: field,
              rule: "contains",
              value: e.target.value,
            },
          ],
    });
  };
  const handleSelect = (newValue: any, field: string) => {
    if (field === "coating_ids") {
      setFilter({
        ...filter,
        coating_ids: newValue,
      });
      return;
    }
    setFilter({
      ...filter,
      filters: filter.filters?.find((el) => el.field_name === field)
        ? filter.filters?.map((el) =>
            el.field_name === field ? { ...el, value: newValue?.id ?? "" } : el
          )
        : [
            ...filter.filters,
            {
              field_name: field,
              rule: "contains",
              value: newValue?.id ?? "",
            },
          ],
    });
  };

  const getValue = (field: string) => {
    return (
      filter?.filters?.find((el) => el?.field_name === field)?.value ?? null
    );
  };

  useEffect(() => {
    return () => {
      resetFilter();
    };
  }, []);
  useEffect(() => {
    setIsShort(media);
  }, [media]);
  return (
    <>
      <Box
        sx={{
          display: "flex",
          gap: "10px",
          flexWrap: "wrap",
          alignItems: "flex-end",
        }}
      >
        <FormControl
          variant="filled"
          sx={{
            minWidth: 120,
            "& .MuiFormLabel-root": {
              top: getValue("installation") ? "0" : "10px",
            },
          }}
        >
          <InputLabel>
          {/* @ts-ignore */}
            {t("common.searchBy.title") +
              " " +
              // @ts-ignore
              t("common.searchBy.installation")}
          </InputLabel>
          <Select
            sx={{
              padding: "0px",
              maxHeight: "40px",
              minWidth: "210px",
              "& .MuiSelect-select": {
                padding: "10px 0 10px 10px",
              },
            }}
            variant={"standard"}
            value={getValue("installation")}
            labelId="filter"
            placeholder={
              // @ts-ignore
              t("common.searchBy.title") + " " + t("common.fieldForSearch")
            }
            onChange={(e) => handleSearch(e, "installation")}
          >
            <MenuItem value="">
              <em>None</em>
            </MenuItem>
            {installations.map((installation) => {
              return (
                <MenuItem key={installation} value={installation}>
                  {/* @ts-ignore */}
                  {t("common." + installation)}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
        <FormControl
          variant="filled"
          sx={{
            minWidth: 120,
            "& .MuiFormLabel-root": {
              top: getValue("product") ? "0" : "10px",
            },
          }}
        >
          <InputLabel>
          {/* @ts-ignore */}
            {t("common.searchBy.title") + " " + t("common.searchBy.product")}
          </InputLabel>
          <Select
            sx={{
              padding: "0px",
              maxHeight: "40px",
              minWidth: "210px",
              "& .MuiSelect-select": {
                padding: "10px 0 10px 10px",
              },
            }}
            variant={"standard"}
            value={getValue("product")}
            labelId="filter"
            // @ts-ignore
            placeholder={t("common.fieldForSearch")}
            onChange={(e) => handleSearch(e, "product")}
          >
            <MenuItem value="">
              <em>None</em>
            </MenuItem>
            {products.map((product) => {
              return (
                <MenuItem key={product} value={product}>
                  {/* @ts-ignore */}
                  {t("common." + product)}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
        <Autocomplete
          multiple
          options={coatings?.map((el) => el.id) ?? []}
          onChange={(event: any, newValue: any[]) =>
            handleSelect(newValue ?? [], "coating_ids")
          }
          getOptionLabel={(option) =>
            coatings.find((el) => el.id === option)?.code ?? ""
          }
          value={filter?.coating_ids ?? []}
          defaultValue={[]}
          filterSelectedOptions
          sx={{
            minWidth: "250px",
            "& .MuiChip-label": {
              color: "#000",
            },
          }}
          renderInput={(params) => (
            //@ts-ignore
            <TextField
              {...params}
              label={
                // @ts-ignore
                t("common.searchBy.title") + " " + t("common.searchBy.coating")
              }
              variant="standard"
            />
          )}
        />
        <br />
      </Box>
      {media && (
        <Button
          onClick={() => setIsShort((prev) => !prev)}
          variant={"outlined"}
          endIcon={
            <ExpandMoreIcon
              sx={{ transform: !isShort ? "rotate(180deg)" : "rotate(0deg)" }}
            />
          }
          color={"warning"}
          sx={{
            transition: "all 0.3s ease",
            "& svg": {
              transition: "all 0.3s ease",
            },
          }}
        >
          {/* @ts-ignore */}
          {isShort ? t("common.showMore") : t("common.showLess")}
        </Button>
      )}
    </>
  );
};
export default Filter;
