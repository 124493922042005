import {TableHead, TableRow} from "@mui/material";
import {useTranslation} from "react-i18next";
import {StyledTableCell} from "./style";

const LidsTableHead = () => {
  const { t } = useTranslation()

  return <TableHead sx={{
    borderBottom: '3px double #ccc'
  }}>
    <TableRow>
      <StyledTableCell>
        {/* @ts-ignore */}
        {t('lids.id')}
      </StyledTableCell>
      <StyledTableCell>
        {/* @ts-ignore */}
          {t('lids.name_en')}
        </StyledTableCell>
        <StyledTableCell>
          {/* @ts-ignore */}
          {t('lids.name_uk')}
        </StyledTableCell>
        <StyledTableCell>
          {/* @ts-ignore */}
          {t('lids.article_prefix_1')}
        </StyledTableCell>
        <StyledTableCell>
          {/* @ts-ignore */}
          {t('lids.article_prefix_2')}
        </StyledTableCell>
        <StyledTableCell>
          {/* @ts-ignore */}
          {t('lids.type')}
        </StyledTableCell>
        <StyledTableCell>
          {/* Add any additional columns if needed */}
        </StyledTableCell>
      </TableRow>
  </TableHead>
}

export default LidsTableHead