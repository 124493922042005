import { Box, Typography, useMediaQuery } from "@mui/material";
import Filter from "./components/Trays/Filter";
import { useEffect } from "react";
import { useProjectStore } from "../../../store/projectStore";
import useAsyncWrapper from "../../../hooks/useAsyncWrapper";
import ResultList from "./components/Trays/ResultList";
import ChoosenItem from "./components/Trays/ChoosenItem";
import choosenItem from "./components/Trays/ChoosenItem";
import { useTranslation } from "react-i18next";
import ProjectHelper from "./components/ProjectHelper";
import ProjectComputation from "./components/ProjectComputation";
import FilterAccessories from "./components/Accessory/FilterAccessories";
import ChoosenAccessory from "./components/Accessory/ChoosenAccessory";
import ResultListAccessory from "./components/Accessory/ResultListAccessory";

const ProjectInfo = () => {
  const { t } = useTranslation();

  const media = useMediaQuery("(min-width: 900px)");

  const choosenItem = useProjectStore((state) => state.choosenItem);

  const choosenAccessory = useProjectStore((state) => state.choosenAccessory);
  const setChoosenItem = useProjectStore((state) => state.setChoosenAccessory);
  const accessoryView = useProjectStore((state) => state.accessoryView);
  const setAccessoryView = useProjectStore((state) => state.setAccessoryView);

  const setCurrentPosition = useProjectStore(
    (state) => state.setCurrentPosition
  );

  const project_positions = useProjectStore((state) => state.project_positions);

  useEffect(() => {
    if (!project_positions?.length) {
      setAccessoryView(false);
    }
  }, [!project_positions?.length]);

  useEffect(() => {
    if (accessoryView) {
      setChoosenItem(null);
      setCurrentPosition(null);
    } else {
      setChoosenItem(null);
    }
  }, [accessoryView]);

  return (
    <Box
      sx={{
        flexGrow: 1,
        overflowY: "auto",
        maxHeight: "calc(100vh - 67px)",
        display: "flex",
        gap: "20px",
        paddingTop: "5px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "10px",
          width: "55%",
          height: "100%",
          minWidth: "min-content",
          "@media (max-width: 900px)": {
            width: "100%",
          },
        }}
      >
        <Box
          sx={{
            display: "flex",
            gap: "0 15px",
            "@media (max-width: 1100px)": {
              flexDirection: "column-reverse",
              flexWrap: "wrap",
            },
          }}
        >
          {accessoryView ? <FilterAccessories /> : <Filter />}
          <ProjectHelper />
        </Box>
        {accessoryView ? <ResultListAccessory /> : <ResultList />}
        {media ? (
          choosenItem || choosenAccessory ? (
            accessoryView ? (
              <ChoosenAccessory />
            ) : (
              <ChoosenItem />
            )
          ) : (
            <Typography
              variant={"h4"}
              sx={{
                textAlign: "center",
                flexGrow: 1,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: "100%",
              }}
            >
              {/* @ts-ignore */}
              {t("common.chooseElement")}
            </Typography>
          )
        ) : null}
      </Box>
      {media && <ProjectComputation />}
    </Box>
  );
};
export default ProjectInfo;
