import React, {useState} from "react";
import {Box, Button, Dialog, DialogContent, DialogTitle, Grid, TextField, Typography} from "@mui/material";
import {useTranslation} from "react-i18next";
import CloseSvg from "../../../../assets/svgs/common/CloseSvg";
import {Controller, useForm} from "react-hook-form";
import {StyledFormControlLabel} from "../SignUp/style";
import {ILogin, IResetPassword} from "../../../../interfaces/general";
import {yupResolver} from "@hookform/resolvers/yup";
import {getForgotPasswordSchema} from "../../../../validations/auth/forgotPassword";
import ButtonLoadingWrapper from "../../../Wrappers/ButtonLoading";
import useToast from "../../../../hooks/useToast";
import {useUserStore} from "../../../../store/userStore";
import useAsyncWrapper from "../../../../hooks/useAsyncWrapper";
import {StyledDialogTitle} from "./style";
import {Simulate} from "react-dom/test-utils";
import error = Simulate.error;

interface IForgotPasswordProps {
  open: boolean;
  handleClose: () => void;
}
const ForgotPassword = ( {handleClose, open} : IForgotPasswordProps) => {
  const { t } = useTranslation()
  const { errorToast, successToast } = useToast()

  const [step, setStep] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  const [{recovery_code, email, new_password}, setFormValues] = useState<IResetPassword>({ email: '', recovery_code: '', new_password: ''  })

  const forgotPasswordEmail = useUserStore(state => state.forgotPasswordEmail);
  const forgotPasswordCheck = useUserStore(state => state.forgotPasswordCheck);
  const forgotPasswordChange = useUserStore(state => state.forgotPasswordChange);

  const forgotPasswordEmailHandler = useAsyncWrapper(forgotPasswordEmail)
  const forgotPasswordCheckHandler = useAsyncWrapper(forgotPasswordCheck)
  const forgotPasswordHandler = useAsyncWrapper(forgotPasswordChange)


  const forgotPasswordSubmit = async (e: any) => {
    e.preventDefault()
    setIsLoading(true)
    if(step === 0) {
      const {error} = await forgotPasswordEmailHandler( [{email}] )
      if(!error) {
        // @ts-ignore
        successToast(t('forgotPassword.success.email'));
        setStep(prev => prev + 1)
      } else {
        // @ts-ignore
        errorToast(t('forgotPassword.error.email'));
      }
    } else if(step === 1) {
      const {error} = await forgotPasswordCheckHandler( [{email, recovery_code}] )
      if(!error) {
        // @ts-ignore
        successToast(t('forgotPassword.success.code'));
        setStep(prev => prev + 1)
      } else {
        // @ts-ignore
        errorToast(t('forgotPassword.error.code'));
      }
    } else if(step === 2) {
      const {error} = await forgotPasswordHandler( [{email, recovery_code, new_password }] )
      if(!error) {
        // @ts-ignore
        successToast(t('forgotPassword.success.password'));
        handleClose()
      } else {
        // @ts-ignore
        errorToast(t('forgotPassword.error.new_password'));
      }
    }

    setIsLoading(false)

  }

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormValues(prev => ({
      ...prev,
      [e.target.name]: e.target.value
    }))
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
    >
      <StyledDialogTitle>
        {/* @ts-ignore */}
          {t('forgotPassword.title')}
        <Box onClick={handleClose}>
          <CloseSvg />
        </Box>
      </StyledDialogTitle>


      <DialogContent>
        <Box
          component={'form'}
          onSubmit={forgotPasswordSubmit}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '20px',
            '@media (min-width: 600px)': {
              minWidth: '350px',
            }
          }}
        >
              <StyledFormControlLabel
                labelPlacement="top"
                name={"email"}
                sx={{
                  alignItems: 'flex-start'
                }}
                // @ts-ignore
                label={t("forgotPassword.email")}
                control={
                  <TextField
                    disabled={step > 0}
                    fullWidth
                    type={'email'}
                    required
                    onChange={onChange}
                    value={email}
                  />
                }
              />
              {step > 0 && <StyledFormControlLabel
                labelPlacement="top"
                name={"recovery_code"}
                sx={{
                  alignItems: 'flex-start'
                }}
                // @ts-ignore
                label={t("forgotPassword.code")}
                control={
                  <TextField
                    type={"number"}
                    disabled={step > 1}
                    required
                    fullWidth
                    onChange={onChange}
                    value={recovery_code}
                  />
                }
              />}
              {step > 1 && <StyledFormControlLabel
                labelPlacement="top"
                name={"new_password"}
                sx={{
                  alignItems: 'flex-start'
                }}
                // @ts-ignore
                label={t("forgotPassword.new_password")}
                control={
                  <TextField
                    fullWidth
                    required
                    type={'password'}
                    onChange={onChange}
                    value={new_password}
                  />
                }
              />}

          <ButtonLoadingWrapper isLoading={isLoading}>
            <Button
              variant="contained"
              fullWidth
              type='submit'
              size="large"
              sx={{
                background: '#373737',
                '&:hover': {
                  background: '#666',
                }
              }}
            >

{/* @ts-ignore */}
              {t("forgotPassword.submit")}
            </Button>
          </ButtonLoadingWrapper>
        </Box>
      </DialogContent>
    </Dialog>
  );
};


export default ForgotPassword;
