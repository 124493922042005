import { Autocomplete, Box, InputAdornment, TextField } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import Sizes from "./components/Sizes";
import CustomAutocomplete from "./components/CustomAutocomplete";
import { useProjectStore } from "../../../../../../store/projectStore";
import useLang from "../../../../../../hooks/useLang";
import { useCoatingsStore } from "../../../../../../store/coatingsStore";
import useAsyncWrapper from "../../../../../../hooks/useAsyncWrapper";

const Filter = () => {
  const { t } = useTranslation();

  const { getTranslate } = useLang();
  const trays = useProjectStore((state) => state.traysData).data;
  ///
  const setCurrentTray = useProjectStore((state) => state.setCurrentTray);
  const currentTray = useProjectStore((state) => state.currentTray);
  ///
  const search = useProjectStore((state) => state.search);
  const setSearch = useProjectStore((state) => state.setSearch);
  //
  const coatings = useCoatingsStore((state) => state.tableData)?.data ?? [];
  const setCurrentCoating = useProjectStore((state) => state.setCurrentCoating);
  const currentCoating = useProjectStore((state) => state.currentCoating);
  //
  const modelValues = useProjectStore((state) => state.model_values);
  const setCurrentModelValues = useProjectStore(
    (state) => state.setCurrentModelValues
  );
  const currentModelValues = useProjectStore(
    (state) => state.currentModelValues
  );
  const setChoosenItem = useProjectStore((state) => state.setChoosenAccessory);
  const setCurrentPosition = useProjectStore(
    (state) => state.setCurrentPosition
  );
  const getModelValues = useProjectStore((state) => state.getModelValues);
  const clearModelValues = useProjectStore((state) => state.clearModelValues);

  const recommendation = useProjectStore(
    (state) => state.recommendation.choosenRec
  );

  const filter = useProjectStore((state) => state.filter);

  const getProjectTrays = useProjectStore((state) => state.getProjectTrays);
  const getProjectTraysHandler = useAsyncWrapper(getProjectTrays);
  const getModelValuesHandler = useAsyncWrapper(getModelValues);

  const handleChangeValue = (type: "width" | "height" | "length") => {
    return (value: any) =>
      setCurrentModelValues({
        ...currentModelValues,
        [type]: value,
      });
  };

  useEffect(() => {
    (async () => {
      setChoosenItem(null);
      setCurrentPosition(null);
      if (currentTray) await getModelValuesHandler([currentTray.id, "tray"]);
      else {
        clearModelValues();
      }
    })();
  }, [currentTray]);

  useEffect(() => {
    (async () => {
      await getProjectTraysHandler([filter, 1]);
    })();
  }, [filter]);

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "flex-end",
        gap: "10px",
        marginBottom: "10px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "10px",
        }}
      >
        <TextField
        // @ts-ignore
          label={t("common.search")}
          type="search"
          variant="standard"
          onChange={(e) => setSearch(e.target.value)}
          sx={{
            minWidth: "250px",
          }}
          disabled={!!recommendation}
          value={search}
          InputProps={{
            endAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
        <Autocomplete
          options={trays ?? []}
          onChange={(event: any, newValue: any) => setCurrentTray(newValue)}
          getOptionLabel={(option) => getTranslate("name", option) ?? ""}
          value={currentTray}
          disabled={!!recommendation}
          isOptionEqualToValue={(option, value) => option?.id === value?.id}
          sx={{
            minWidth: "250px",
          }}
          renderInput={(params) => {
            return (
              //@ts-ignore
              <TextField
                {...params}
                // @ts-ignore
                label={t("common.type")}
                variant="standard"
              />
            );
          }}
        />
        <Autocomplete
          options={
            (!recommendation ? coatings : recommendation?.coatings) ?? []
          }
          onChange={(event: any, newValue: any) => setCurrentCoating(newValue)}
          getOptionLabel={(option) => getTranslate("name", option) ?? ""}
          isOptionEqualToValue={(option, value) => option?.id === value?.id}
          value={currentCoating}
          disabled={!currentTray && !recommendation}
          sx={{
            minWidth: "250px",
          }}
          renderInput={(params) => (
            //@ts-ignore
            <TextField
              {...params}
              // @ts-ignore
              label={t("common.coating")}
              variant="standard"
            />
          )}
        />
      </Box>

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "10px",
        }}
      >
        <CustomAutocomplete
        // @ts-ignore
          label={t("common.width")}
          list={modelValues?.width ?? []}
          handleChange={handleChangeValue("width")}
          inputValue={currentModelValues.width}
          disabled={!currentTray}
        />
        <CustomAutocomplete
        // @ts-ignore
          label={t("common.height")}
          list={modelValues?.height ?? []}
          handleChange={handleChangeValue("height")}
          inputValue={currentModelValues.height}
          disabled={!currentTray}
        />
        <CustomAutocomplete
        // @ts-ignore
          label={t("common.length")}
          list={modelValues?.length ?? []}
          handleChange={handleChangeValue("length")}
          inputValue={currentModelValues.length}
          disabled={!currentTray}
        />
      </Box>
    </Box>
  );
};

export default Filter;
