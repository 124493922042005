import {Box, Dialog, DialogContent, Typography} from "@mui/material";
import {StyledDialogTitle} from "../../Auth/ForgotPassword/style";
import CloseSvg from "../../../../assets/svgs/common/CloseSvg";
import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import ResetPasswordForm from "./ResetPasswordForm";

interface IProps {
  open: boolean;
  handleClose: () => void;
}
const ResetPassword = ({ handleClose, open } : IProps ) => {
  const { t } = useTranslation()



  return <Dialog
    open={open}
    onClose={handleClose}
  >
    <StyledDialogTitle>
      <Typography
        fontWeight={600}
        variant={'h6'}
        color={'#373737'}
      >
        {/* @ts-ignore */}
        {t('resetPassword.title')}
      </Typography>
      <Box onClick={handleClose}>
        <CloseSvg />
      </Box>
    </StyledDialogTitle>

    <DialogContent>
      <ResetPasswordForm />
    </DialogContent>
  </Dialog>

}

export default ResetPassword