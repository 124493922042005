import {
  Box,
  FormControl,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { handbookLangs, handbooksType } from "../../../../../constants";
import { useImagesStore } from "../../../../../store/imagesStore";

const Filter = () => {
  const { t } = useTranslation();

  const filter = useImagesStore((state) => state.filter);
  const setFilter = useImagesStore((state) => state.setFilter);
  const resetFilter = useImagesStore((state) => state.resetFilter);

  const handleSearch = (e: any, field: string) => {
    setFilter({
      ...filter,
      filters: filter.filters?.find((el) => el.field_name === field)
        ? filter.filters?.map((el) =>
            el.field_name === field ? { ...el, value: e.target.value } : el
          )
        : [
            ...filter.filters,
            {
              field_name: field,
              rule: "contains",
              value: e.target.value,
            },
          ],
    });
  };

  const getValue = (field: string) => {
    return filter?.filters?.find((el) => el?.field_name === field)?.value ?? "";
  };

  useEffect(() => {
    return () => {
      resetFilter();
    };
  }, []);
  return (
    <Box
      sx={{
        display: "flex",
        gap: "10px",
        flexWrap: "wrap",
        alignItems: "flex-end",
      }}
    >
      <TextField
      // @ts-ignore
        label={t("common.searchBy.title") + " " + t("common.searchBy.name")}
        type="search"
        variant="standard"
        onChange={(e) => handleSearch(e, "file_name")}
        sx={{
          minWidth: "250px",
        }}
        value={getValue("file_name")}
        InputProps={{
          endAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
      />
    </Box>
  );
};
export default Filter;
