import { TFunction } from "i18next";
import * as Yup from "yup";

export const getTrayCreateValidationSchema = (t: (key: string) => string) =>
  Yup.object().shape({
    name_uk: Yup.string()
      .min(2, t("error.required"))
      .required(t("error.required")),
    name_en: Yup.string(),
    article_prefix_2: Yup.string(),
    article_prefix_1: Yup.string(),
    type: Yup.string().required(t("error.required")),
    lid_ids: Yup.array(),
    partition_ids: Yup.array(),
  });
