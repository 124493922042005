import { Box, Button } from "@mui/material";
import React, { memo, useEffect, useState } from "react";
import { useProjectStore } from "../../../../../store/projectStore";
import CartList from "./CartList";
import { useTranslation } from "react-i18next";
import TableLoading from "../../../TableLoading";
import DownloadIcon from "@mui/icons-material/Download";
import BackupTableIcon from "@mui/icons-material/BackupTable";
import SendToEmail from "../../../Modals/SendToEmail";
import useAsyncWrapper from "../../../../../hooks/useAsyncWrapper";
import { useProjectsStore } from "../../../../../store/projectsStore";
import useToast from "../../../../../hooks/useToast";

const ProjectComputation = () => {
  const { t } = useTranslation();
  const { successToast, errorToast } = useToast();
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const loadingCart = useProjectStore((state) => state.loadingCart);

  const getResult = useProjectStore((state) => state.getResult);
  const currentProject = useProjectsStore((state) => state.currentProject);
  const getResultHandler = useAsyncWrapper(getResult);

  const handleDownload = async () => {
    const payload = {
      project_id: currentProject?.id,
      type: "export",
    };
    setLoading(true);
    const { error, data } = await getResultHandler([payload]);
    setTimeout(() => setLoading(false), 1000);

    const blob = new Blob([data], { type: "text/plain" });
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = `${currentProject?.name}.xlsx`;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);

    if (!error) {
      return;
    }
    // @ts-ignore
    errorToast(t("error.somethingWentWrong"));
  };

  const handleSave = async () => {
    const payload = {
      project_id: currentProject?.id,
      type: "sendEmail",
      email_addresses: [],
    };

    setLoading(true);
    const { error } = await getResultHandler([payload]);
    setLoading(false);

    if (!error) {
      // @ts-ignore
      successToast(t("common.emailSent"));
      return;
    }
    // @ts-ignore
    errorToast(t("error.somethingWentWrong"));
  };

  return (
    <Box
      sx={{
        flexGrow: 1,
        overflowY: "hidden",
        height: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Box
        sx={{
          display: "flex",
          gap: "10px",
          mb: "5px",
          "@media (max-width: 1000px)": {
            "& .MuiButton-root": {
              fontSize: "12px",
            },
          },
        }}
      >
        <Button
          fullWidth
          endIcon={<DownloadIcon />}
          variant={"contained"}
          onClick={handleDownload}
          disabled={loading}
          color={"error"}
        >
          {/* @ts-ignore */}
          {t("common.download")}
        </Button>
        <Button
          fullWidth
          endIcon={<BackupTableIcon />}
          color={"success"}
          variant={"contained"}
          disabled={loading}
          onClick={handleSave} //() => setOpen(true)
        >
          {/* @ts-ignore */}
          {t("common.send_to_emails")}
        </Button>
      </Box>
      <Box
        sx={{
          display: "flex",
          gap: "10px",
          alignItems: "center",
          backgroundColor: "#242531",
          fontWeight: "bold",
          color: "#fff",
          fontSize: "0.875rem",
          padding: "5px 10px",
          borderRadius: "5px",
          height: "52px",
        }}
      >
        <Box
          sx={{
            width: "60%",
          }}
        >
          {/* @ts-ignore */}
          {t("common.description")}
        </Box>
        <Box
          sx={{
            width: "20%",
          }}
        >
          {/* @ts-ignore */}
          {t("common.article")}
        </Box>
        <Box
          sx={{
            width: "10%",
            textAlign: "center",
          }}
        >
          {/* @ts-ignore */}
          {t("common.count")}
        </Box>
        <Box
          sx={{
            width: "10%",
            textAlign: "center",
          }}
        >
          {/* @ts-ignore */}
          {t("common.unit_of_measurement")}
        </Box>
      </Box>
      {loadingCart ? <TableLoading /> : <CartList />}
      {/*<SendToEmail open={open} handleClose={() => setOpen(false)}/>*/}
    </Box>
  );
};

export default memo(ProjectComputation);
