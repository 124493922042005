import { Box, Button, Typography } from "@mui/material";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useRecommendationsStore } from "../../../../../../store/recommendationsStore";
import {
  initialState,
  useProjectStore,
} from "../../../../../../store/projectStore";
import useLang from "../../../../../../hooks/useLang";
import CheckIcon from "@mui/icons-material/Check";
import useAsyncWrapper from "../../../../../../hooks/useAsyncWrapper";
import useToast from "../../../../../../hooks/useToast";

interface IProps {
  handleClose: () => void;
}

const RecommendationChoose = ({ handleClose }: IProps) => {
  const { t } = useTranslation();
  const [currentRec, setCurrentRec] = useState<any>(null);
  const { getTranslate } = useLang();
  const { infoToast } = useToast();

  const recommendation = useProjectStore((state) => state.recommendation);

  const { recData } = recommendation;

  const setRecommendation = useProjectStore((state) => state.setRecommendation);
  const setCurrentTray = useProjectStore((state) => state.setCurrentTray);

  const handleChoose = (item: any) => {
    setCurrentRec(item);
  };
  const handleSubmit = async () => {
    setRecommendation({
      ...recommendation,
      choosenRec: currentRec,
    });
    setCurrentTray(null);
    // @ts-ignore
    infoToast(t("project.recommendation.successChoose"));
    handleClose();
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "10px",
          maxHeight: "600px",
          overflowY: "auto",
        }}
      >
        {recData?.data?.map((item, index) => (
          <Box
            key={item?.id}
            onClick={() => handleChoose(item)}
            sx={{
              cursor: "pointer",
              display: "flex",
              gap: "10px",
              alignItems: "center",
              padding: "5px 10px",
              borderBottom:
                currentRec?.id === item?.id
                  ? "2px solid green"
                  : "1px solid #ccc",
              backgroundColor:
                currentRec?.id === item?.id ? "#e6ffe6" : "white",
            }}
          >
            <Typography variant={"body1"}>
              {getTranslate("place_installation", item)}
            </Typography>
            {currentRec?.id === item?.id && <CheckIcon />}
          </Box>
        ))}
      </Box>

      <Box
        sx={{
          display: "flex",
          gap: "10px",
        }}
      >
        <Button
          fullWidth
          onClick={handleClose}
          color={"warning"}
          variant={"contained"}
        >
          {/* @ts-ignore */}
          {t("common.cancel")}
        </Button>
        <Button
          fullWidth
          onClick={handleSubmit}
          color={"error"}
          variant={"contained"}
        >
          {/* @ts-ignore */}
          {t("common.use")}
        </Button>
      </Box>
    </>
  );
};

export default RecommendationChoose;
