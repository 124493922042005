import {Controller, useForm} from "react-hook-form";
import {ICreateCoatingForm,
} from "../../../../../interfaces/general";
import {yupResolver} from "@hookform/resolvers/yup";
import {StyledFormControlLabel} from "./style";
import {Box, Button, Grid, TextField} from "@mui/material";
import ButtonLoadingWrapper from "../../../../Wrappers/ButtonLoading";
import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import useAsyncWrapper from "../../../../../hooks/useAsyncWrapper";
import useToast from "hooks/useToast";

import {useCoatingsStore} from "../../../../../store/coatingsStore";
import {getCoatingCreateValidationSchema} from "../../../../../validations/coatings/createCoating";


interface IProps {
  handleClose: () => void;
}
const initialFormState = {
  name_en: '',
  name_uk: '',
  code: '',
}

const CreateCoatingForm = ({ handleClose }: IProps) => {
  const { t } = useTranslation()
  const { errorToast, successToast } = useToast();

  const [isLoading, setIsLoading] = useState(false);

  const createCoating = useCoatingsStore(state => state.createCoating);
  const coatings = useCoatingsStore(state => state.tableData.data);

  const createCoatingHandler = useAsyncWrapper(createCoating)

  const schema = getCoatingCreateValidationSchema(t);

  const {handleSubmit, control, watch } = useForm<ICreateCoatingForm>({
    defaultValues: initialFormState,
    resolver: yupResolver(schema),
  });

  const handleCreateSubmit = async (body: ICreateCoatingForm) => {

    setIsLoading(true)
    const { error, status } = await createCoatingHandler( [body] )
    setIsLoading(false)

    if(!error) {
      // @ts-ignore
      successToast(t('createCoating.success'))
      handleClose()
      return;
    }
    if (status === 400) {
      const errors = error?.response?.data?.message ?? [];
      if(error?.response?.data?.message.length > 0) {
        // @ts-ignore
        errors.forEach((err: any) => errorToast(t('error.error'), t('createCoating.error.400.' + err?.field)))
      }

      return
    }

    // @ts-ignore
    errorToast(t('createCoating.error.default'))
  }


  return (
    <Box
      component={'form'}
      onSubmit={handleSubmit(handleCreateSubmit)}
      sx={{
        '@media (min-width: 600px)': {
          minWidth: '350px',
        }
      }}
    >
      <Grid
        container
        spacing={1}
        mb={2}
      >
        <Grid item xs={12} md={12}>
          <Controller
            name={"code"}
            control={control}
            render={({
                       fieldState: {error},
                       field: {onChange, value, name},
                     }) => (
              <StyledFormControlLabel
                labelPlacement="top"
                sx={{
                  alignItems: 'flex-start'
                }}
                // @ts-ignore
                label={t("coatings.code")}
                control={
                  <TextField
                    fullWidth
                    onChange={onChange}
                    value={value}
                    error={error?.ref?.name === name}
                    helperText={error ? error.message : ' '}
                  />
                }
              />
            )}
          />
        </Grid><Grid item xs={12} md={12}>
          <Controller
            name={"name_uk"}
            control={control}
            render={({
                       fieldState: {error},
                       field: {onChange, value, name},
                     }) => (
              <StyledFormControlLabel
                labelPlacement="top"
                sx={{
                  alignItems: 'flex-start'
                }}
                // @ts-ignore
                label={t("coatings.descriptionUa")}
                control={
                  <TextField
                    fullWidth
                    onChange={onChange}
                    value={value}
                    error={error?.ref?.name === name}
                    helperText={error ? error.message : ' '}
                  />
                }
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <Controller
            name={"name_en"}
            control={control}
            render={({
                       fieldState: {error},
                       field: {onChange, value, name},
                     }) => (
              <StyledFormControlLabel
                labelPlacement="top"
                sx={{
                  alignItems: 'flex-start'
                }}
                // @ts-ignore
                label={t("coatings.descriptionEn")}
                control={
                  <TextField
                    fullWidth
                    onChange={onChange}
                    value={value}
                    error={error?.ref?.name === name}
                    helperText={error ? error.message : ' '}
                  />
                }
              />
            )}
          />
        </Grid>

      </Grid>
      <ButtonLoadingWrapper isLoading={isLoading}>
        <Button
          variant="contained"
          type="submit"
          fullWidth
          size="large"
          sx={{
            background: '#373737',
            '&:hover': {
              background: '#666',
            }
          }}
        >
{/* @ts-ignore */}
          {t("createCoating.submit")}
        </Button>
      </ButtonLoadingWrapper>
    </Box>
  )
}
export default CreateCoatingForm