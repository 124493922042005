import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import CloseSvg from "../../../../assets/svgs/common/CloseSvg";
import React, { memo, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useProjectStore } from "../../../../store/projectStore";
import useLang from "../../../../hooks/useLang";
import useAsyncWrapper from "../../../../hooks/useAsyncWrapper";
import useToast from "../../../../hooks/useToast";
import { Check } from "@mui/icons-material";

interface ISettingsProps {
  open: boolean;
  handleClose: () => void;
}
const ChooseTypeLid = ({ handleClose, open }: ISettingsProps) => {
  const { t } = useTranslation();
  const { getTranslate } = useLang();
  const { errorToast } = useToast();

  const [loading, setLoading] = useState(false);
  const [tempLid, setTempLid] = useState<any>(null);

  const setIsLid = useProjectStore((state) => state.setLid);
  const choosenItem = useProjectStore((state) => state.choosenItem);
  const currentPosition = useProjectStore((state) => state.currentPosition);
  const lids = choosenItem?.tray?.lids ?? [];

  const setChoosenLid = useProjectStore((state) => state.setChoosenLid);
  const getLidsByTray = useProjectStore((state) => state.getLidsByTray);

  const getLidsByTrayHandler = useAsyncWrapper(getLidsByTray);

  const cancel = () => {
    handleClose();
    if (!currentPosition?.lid_model_parameter?.lid_id) setIsLid(false);
  };

  const chooseLid = (lid: any) => {
    setTempLid(lid);
  };

  const handleChoose = async () => {
    if (!tempLid) return;

    const payload = {
      tray_model_parameter_id: choosenItem?.id,
      lid_id: tempLid?.id,
    };
    setLoading(true);
    const { error, data } = await getLidsByTrayHandler([payload]);
    setLoading(false);

    if (!!error || !data.data) {
      // @ts-ignore
      errorToast(t("project.noLidFoundForTray"));
      setIsLid(false);
      handleClose();
      return;
    }

    setChoosenLid(data.data);
    handleClose();
  };

  useEffect(() => {
    if (open) {
      if (currentPosition?.lid_model_parameter) {
        const lidId = currentPosition?.lid_model_parameter?.lid_id;
        setTempLid(lidId ? { id: lidId } : null);
      } else {
        const defaultLid = lids?.find((item: any) =>
          item?.article_prefix_1?.includes("KTK1")
        );
        setTempLid(defaultLid ?? null);
      }
    }
  }, [open, currentPosition]);

  return (
    <Dialog
      open={open}
      onClose={cancel}
      sx={{
        "& .MuiBackdrop-root": {
          backgroundColor: "rgba(0,0,0,0.1)", // Try to remove this to see the result
        },
        "& .MuiDialog-paper": {
          boxShadow: "none",
          maxWidth: "900px",
        },
      }}
    >
      <DialogTitle
        sx={{
          display: "flex",
          justifyContent: "space-between",
          fontWeight: 600,
          color: "#373737",
        }}
      >
        {/* @ts-ignore */}
        {t("project.chooseTypeLid")}

        <Box onClick={cancel}>
          <CloseSvg />
        </Box>
      </DialogTitle>

      <DialogContent
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "25px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            gap: "15px",
            "@media (max-width: 900px)": {
              flexWrap: "wrap",
            },
          }}
        >
          {lids?.length
            ? lids?.map((item: any, i: number) => (
                <Button
                  key={item?.id}
                  disabled={loading || tempLid?.id === item?.id}
                  variant={i % 2 ? "outlined" : "contained"}
                  color={i % 2 ? "secondary" : "error"}
                  onClick={() => chooseLid(item)}
                  endIcon={tempLid?.id === item?.id ? <Check /> : null}
                  fullWidth
                >
                  {getTranslate("name", item)}
                </Button>
              ))
            : "No lids"}
        </Box>

        <Box
          sx={{
            display: "flex",
            gap: "10px",
          }}
        >
          <Button
            fullWidth
            onClick={cancel}
            color={"warning"}
            variant={"outlined"}
          >
            {/* @ts-ignore */}
            {t("common.cancel")}
          </Button>
          <Button
            fullWidth
            onClick={handleChoose}
            color={"primary"}
            disabled={!tempLid || loading}
            variant={"contained"}
            sx={{
              backgroundColor: "#666",
              "&:hover": {
                backgroundColor: "#ccc",
              },
            }}
          >
            {/* @ts-ignore */}
            {t("common.save")}
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default memo(ChooseTypeLid);
