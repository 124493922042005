import { TFunction } from "i18next";
import * as Yup from "yup";

export const getMetalProductEditValidationSchema = (
  t: (key: string) => string
) =>
  Yup.object().shape({
    name_uk: Yup.string()
      .min(2, t("error.required"))
      .required(t("error.required")),
    name_en: Yup.string(),
    article_prefix_2: Yup.string(),
    article_prefix_1: Yup.string(),
    // unit_of_measurement: Yup.string().required(t("error.required")),
    // coating_ids: Yup.array().required(t("error.required")),
    // thickness: Yup.array().required(t("error.required")),
    // length: Yup.array()
    //   .required(t("error.required")),
    // width: Yup.array().required(t("error.required")),
    // height: Yup.array().required(t("error.required")),
    // weight: Yup.array().required(t("error.required")),
  });
