
import React, {useState} from "react";
import {Box, Dialog, FormControl, InputAdornment, TextField, useMediaQuery} from "@mui/material";
import {SketchPicker} from "react-color";
import {useProjectStore} from "../../../../../../../../store/projectStore";
import EditIcon from "@mui/icons-material/Edit";
import {useTranslation} from "react-i18next";
import {rals} from "../../../../../../../../constants";
import {StyledDialogTitle} from "../../../../../../Auth/ForgotPassword/style";
import CloseSvg from "../../../../../../../../assets/svgs/common/CloseSvg";

const ColorPicker = () => {
  const { t } = useTranslation()
  const media = useMediaQuery('(max-width: 900px)');

  const [modal, setModal] = useState(false);

  const currentColor = useProjectStore(state => state.currentColor);
  const setCurrentColor = useProjectStore(state => state.setColor);

  const handleChange = (color: any) => {
    setCurrentColor(color);
    setModal(false)
  };



  return <Box sx={{
    height: 'min-content',
    width: 'minContent',
  }}>
    <Box sx={{
      maxWidth: '240px',
      marginTop: '10px',
    }}>
      <TextField
      // @ts-ignore
        label={t('common.color')}
        type={'search'}
        value={currentColor?.hex ?? ''}
        sx={{
          minWidth: '170px',
          '& .MuiInputBase-input, .MuiFormLabel-root': {
            fontSize: '16px',
          }
        }}
        variant={'standard'}
        helperText={currentColor?.ral}
        InputProps={{
          readOnly: true,
          startAdornment: (
            <Box
              sx={{
                width: '1.5rem',
                height: '1rem',
                backgroundColor: currentColor?.hex,
                marginRight: '10px'
              }}
            />
          ),
          endAdornment: (
            <InputAdornment position="start" onClick={() => setModal(true)} sx={{ cursor: 'pointer' }}>
              <EditIcon />
            </InputAdornment>
          ),
        }}
      />
    </Box>
    <Dialog open={modal}
            fullScreen={media}
            onClose={() => setModal(false)} sx={{
              "@media (min-width: 900px)": {
                '& .MuiDialog-paper': {
                  maxWidth: 'fit-content',
                }
              }

    }}>
      <StyledDialogTitle>
        {/* @ts-ignore */}
        {t('common.chooseColor')}
        <Box onClick={() => setModal(false)}>
          <CloseSvg />
        </Box>
      </StyledDialogTitle>
      {/*<SketchPicker*/}
      {/*  color={currentColor ?? ''}*/}
      {/*  onChangeComplete={handleChange}*/}
      {/*  presetColors={[*/}
      {/*    "#F0C987",*/}
      {/*    "#C7eE51",*/}
      {/*    "#D47455",*/}
      {/*    "#88DCBE",*/}
      {/*    "#346CD0",*/}
      {/*    "#F5A623",*/}
      {/*    "#44A6D4",*/}
      {/*    "#B3C1C7",*/}

      {/*  ]}*/}
      {/*/>*/}
      <Box sx={{
        display: 'flex',
        flexWrap: 'wrap',
        gap: '10px 15px',
        minWidth: '600px',
        width: '50vw',
        padding: '10px 20px',
        maxHeight: '75vh',
        overflowY: 'auto',
        "@media (max-width: 900px)": {
          width: '100%',
          maxHeight: '90vh',
        }
      }}>
        <Box
          onClick={() => handleChange(null)}
          sx={{
            width: 'calc(20% - 12px)',
            cursor: 'pointer',
            height: '60px',
            padding: '5px',
            color: '#001',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            border: '1px solid #000',
            fontSize: '12px',
            '&:hover': {
              opacity: '0.5'
            },
            "@media (max-width: 900px)": {
              width: 'calc(33% - 10px)',

            }
        }}>
          Remove color
        </Box>
        {Object.entries(rals).map(([key, value]) => <Box
          key={value}
          onClick={() => handleChange({hex: value, ral: key})}
          sx={{
            width: 'calc(20% - 12px)',
            cursor: 'pointer',
            height: '60px',
            padding: '5px',
            color: '#001',
            fontWeight: 'bold',
            fontSize: '12px',
            midWidth: '81px',
            background: value,
            "@media (max-width: 1200px)": {
              fontSize: '10px',
            },
            "@media (max-width: 900px)": {
              width: 'calc(33% - 10px)',

            }
        }}>
          RAL: {key}<br />
          HEX: {value}
      </Box>)}

      </Box>


    </Dialog>
  </Box>
};

export default ColorPicker;