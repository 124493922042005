import {Box, IconButton, TableBody, TableRow, Typography} from "@mui/material";
import {useTranslation} from "react-i18next";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import {StyledActionButton, StyledTableCell} from "./style";
import useAsyncWrapper from "../../../../../../hooks/useAsyncWrapper";
import ConfirmDelete from "../../../../Modals/ConfirmDelete";
import React, {useState} from "react";
import usePermission from "../../../../../../hooks/usePermission";
import {PermissionsUser} from "../../../../../../interfaces/enum";
import useToast from "../../../../../../hooks/useToast";
// import EditCoating from "../../../../Modals/EditCoating";
import {useCoatingsStore} from "../../../../../../store/coatingsStore";
import EditCoating from "../../../../Modals/EditCoating";
import DownloadIcon from "@mui/icons-material/Download";
import {formatDate} from "../../../../../../utils/formatDate";
import {env} from "../../../../../../constants";


interface IProps {
  data: any[]
}
const FilesTableBody = ({ data }: IProps) => {
  const { t } = useTranslation()
  const { errorToast, successToast} = useToast()
  const { hasPermission } = usePermission()



  const getStatus = (id: number) => {
    switch (id) {
      case 1:
        // @ts-ignore
        return t('files.status.1')
      case 2:
        // @ts-ignore
        return t('files.status.2')
      case 3:
        // @ts-ignore
        return t('files.status.3')
      case 4:
        // @ts-ignore
        return t('files.status.4')
      default:
        // @ts-ignore
        return t('files.status.4')
    }
  }
  const getStatusColor = (id: number) => {
    switch (id) {
      case 1:
        return 'gray'
      case 2:
        return 'orange'
      case 3:
        return 'green'
      case 4:
        return 'red'
      default:
        return 'red'
    }
  }

  function handleDownload(url: string, name: string) {
    const link = document.createElement("a");
    link.download = name;
    link.href = env.REACT_APP_DEV_BASE_API_URL +  url;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  return (<TableBody>

    {data
      ?.map((row) => {
        return (
          <TableRow
            hover
            role="checkbox"
            tabIndex={-1}
            key={row.id}
            sx={{
              '&.MuiTableRow-hover:hover': {
                opacity: 0.8,
              },
              '& .MuiTableCell-root': {
                backgroundColor: '#D8D9DA',
                color: 'black'
              }
            }}>
            <StyledTableCell>
              <Typography>{row.id}</Typography>
            </StyledTableCell>
            <StyledTableCell>
              {/* @ts-ignore */}
              <Typography>{t('common.'+row.model)}</Typography>
            </StyledTableCell>
            <StyledTableCell>
              <Typography sx={{
                background: getStatusColor(row.status),
                borderRadius: '5px',
                width: 'fit-content',
                padding: '0 8px'
              }}
                          color={'white'}>
                {getStatus(row?.status)}
              </Typography>
            </StyledTableCell>

            <StyledTableCell>
              {/* @ts-ignore */}
              <Typography fontWeight={600}>{t('common.'+row.action)}</Typography>
            </StyledTableCell>
            <StyledTableCell>
              <Typography>{row.file_name}</Typography>
            </StyledTableCell>
            <StyledTableCell>
              <Typography>{row.error}</Typography>
            </StyledTableCell>
            <StyledTableCell>
              <Typography>{formatDate(row.created_at, true)}</Typography>
            </StyledTableCell>
            <StyledTableCell>
              <IconButton onClick={() => handleDownload(row?.file_url, row?.file_name)}>
                <DownloadIcon color={'warning'} />
              </IconButton>
            </StyledTableCell>
            {/*<StyledTableCell>*/}
            {/*  <Box sx={{*/}
            {/*    display: 'flex',*/}
            {/*    gap: '5px'*/}
            {/*  }}>*/}
            {/*    <StyledActionButton disabled={!hasPermission(PermissionsUser.coatingUpdate)} onClick={() => {}}>*/}
            {/*      <DownloadIcon color={'warning'} />*/}
            {/*    </StyledActionButton>*/}
            {/*  </Box>*/}
            {/*</StyledTableCell>*/}
          </TableRow>
        );
      })}
  </TableBody>)
}

export default FilesTableBody