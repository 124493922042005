import React, { memo, useEffect, useState } from "react";
import {
  Autocomplete,
  Box,
  Divider,
  InputAdornment,
  TextField,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { useTranslation } from "react-i18next";
import { useProjectStore } from "../../../../../../store/projectStore";
import useLang from "../../../../../../hooks/useLang";
import { useDebounce } from "../../../../../../hooks/useDebounce";
import useAsyncWrapper from "../../../../../../hooks/useAsyncWrapper";
import CustomAutocomplete from "../../Trays/Filter/components/CustomAutocomplete";

const FilterAccessories = () => {
  const { t } = useTranslation();
  const { getTranslate } = useLang();

  const [search, setSearch] = useState("");
  const debouncedSearchTerm = useDebounce<string>(search, 500);

  const getProjectTrays = useProjectStore((state) => state.getProjectTrays);
  const getModelValues = useProjectStore((state) => state.getModelValues);

  const project_positions = useProjectStore((state) => state.project_positions);
  const trayAccessory = useProjectStore((state) => state.trayAccessory);
  const clearModelValues = useProjectStore((state) => state.clearModelValues);
  const setTrayAccessory = useProjectStore((state) => state.setTrayAccessory);
  const model_values = useProjectStore((state) => state.model_values);
  const accessoriesFilterList = useProjectStore(
    (state) => state.accessoriesFilterList
  );
  const setSearchAccessory = useProjectStore(
    (state) => state.setSearchAccessory
  );
  const setCurrentTypeAccessory = useProjectStore(
    (state) => state.setCurrentTypeAccessory
  );
  const currentTypeAccessory = useProjectStore(
    (state) => state.currentTypeAccessory
  );
  const setChoosenAccessory = useProjectStore(
    (state) => state.setChoosenAccessory
  );

  const setCurrentModelValuesAccessory = useProjectStore(
    (state) => state.setCurrentModelValuesAccessory
  );
  const currentModelValuesAccessory = useProjectStore(
    (state) => state.currentModelValuesAccessory
  );

  const getProjectTraysHandler = useAsyncWrapper(getProjectTrays);
  const getModelValuesHandler = useAsyncWrapper(getModelValues);

  const handleChangeSearch = (e: any) => {
    setSearch(e.target.value);
  };

  const handleChangeValue = (type: "thickness") => {
    return (value: any) =>
      setCurrentModelValuesAccessory({
        ...currentModelValuesAccessory,
        [type]: value,
      });
  };

  useEffect(() => {
    setSearchAccessory(search);
  }, [debouncedSearchTerm]);

  useEffect(() => {
    setTrayAccessory(project_positions[0] ?? null);
  }, []);

  useEffect(() => {
    (async () => {
      const filter = {
        filters: [],
        perpage: 50,
        page: -1,
        type: "accessory",
      };

      await getProjectTraysHandler([filter, 1]);
    })();
  }, []);

  useEffect(() => {
    (async () => {
      setChoosenAccessory(null);
      if (currentTypeAccessory)
        await getModelValuesHandler([currentTypeAccessory.id]);
      else {
        clearModelValues();
      }
    })();
  }, [currentTypeAccessory]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "10px",
        marginBottom: "10px",
      }}
    >
      <Autocomplete
        options={project_positions ?? []}
        onChange={(event: any, newValue: any) => setTrayAccessory(newValue)}
        getOptionLabel={(option) =>
          getTranslate("description", option?.tray_model_parameter) ?? ""
        }
        value={trayAccessory}
        disableClearable={true}
        isOptionEqualToValue={(option, value) => option?.id === value?.id}
        sx={{
          minWidth: "320px",
        }}
        renderInput={(params) => {
          return (
            //@ts-ignore
            <TextField
              {...params}
              // @ts-ignore
              label={t("common.tray")}
              variant="standard"
            />
          );
        }}
      />
      <Box
        sx={{
          display: "flex",
          gap: "inherit",
        }}
      >
        <Autocomplete
          options={accessoriesFilterList ?? []}
          onChange={(event: any, newValue: any) =>
            setCurrentTypeAccessory(newValue)
          }
          getOptionLabel={(option) => getTranslate("name", option) ?? ""}
          value={currentTypeAccessory}
          isOptionEqualToValue={(option, value) => option?.id === value?.id}
          sx={{
            minWidth: "320px",
          }}
          renderInput={(params) => {
            return (
              //@ts-ignore
              <TextField
                {...params}
                // @ts-ignore
                label={t("common.type")}
                variant="standard"
              />
            );
          }}
        />
        <CustomAutocomplete
        // @ts-ignore
          label={t("common.thickness")}
          list={model_values?.thickness ?? []}
          disabled={!currentTypeAccessory}
          handleChange={handleChangeValue("thickness")}
          inputValue={currentModelValuesAccessory?.thickness}
        />
      </Box>
      <TextField
      // @ts-ignore
        label={t("common.search")}
        type="search"
        variant="standard"
        onChange={handleChangeSearch}
        sx={{
          minWidth: "250px",
        }}
        value={search}
        InputProps={{
          endAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
      />
    </Box>
  );
};

export default memo(FilterAccessories);
